<!--<div class="container-cards">
        <div class="cardTop" style="text-align: center;">
            <img class="iconSize" src="assets/homeIcon.PNG">
            <p>Home</p>
        </div>
        <div class="cardTop" style="text-align: center;">
            <img class="iconSize" src="assets/lostIcon.PNG">
            <p>Lost</p>
        </div>
</div>-->

<div class="flex-container2">
    <div class="wwh" style="text-align: center;">
        <div (click)="openRegisterNewDevice()" class="deviceCardAdd">
            <div class="accentAdd">
                <mat-icon class="iconAdd">add</mat-icon>
            </div>
        </div>
        <div>

            <p class="p2Add">Register a new <br>Device</p>
    
        </div>
    </div>
    <div class="wwh" *ngFor='let device of devices | async; index as i' style="text-align: center;">
        <div (click)="viewDevice(device)" class="deviceCard">
            <div class="accent">
                <mat-icon class="icon">devices</mat-icon>
            </div>
        </div>
        <div>

            <p class="p2">{{ device.nickname }}</p>
    
        </div>
    </div>
</div>