<div class="wider" style="text-align: center;">
    <div class="exitButtonRight">
        <div>
            <img class="logo" src="/assets/OwnedbyBlackChatBack.png">
        </div>
        <div>
            <mat-icon class="exitButton" (click)="exit()">close</mat-icon>
        </div>
    </div>

    <div class="ghost"></div>
    <h1 class="fontOrange">Register Device</h1>
    <form [formGroup]="deviceRegisterForm" class="inputs" (ngSubmit)="onRegisterDevice()">
        <mat-form-field appearance="legacy" style="text-align:center; display:block;">
            <mat-label>Select Device Brand</mat-label>
            <mat-select appearance="legacy" formControlName="brandSubmitted">
                <mat-option *ngFor="let device of devices" [value]="device.name">{{device.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="hasError('brandSubmitted', 'required')">Brand is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="legacy" style="text-align:center; display:block;">
            <mat-label>Device NickName</mat-label>
            <input matInput [matAutocomplete]="auto" formControlName="nicknameSubmitted" id="nicknameField">
            <mat-autocomplete #auto="matAutocomplete"></mat-autocomplete>
            <mat-error *ngIf="hasError('nicknameSubmitted', 'required')">NickName is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="legacy" style="text-align:center; display:block;">
            <mat-label>IMEI Number</mat-label>
            <input matInput formControlName="imeiSubmitted" id="imeiField">
            <mat-error *ngIf="hasError('imeiSubmitted', 'required')">IMEI is required</mat-error>
        </mat-form-field>
        <button mat-flat-button color="primary" class="radius" type="submit" style="text-align:center;">Register Device</button>
    </form>
</div>