<div class="cardSize">
    <div class="exitButtonRight">
        <div>
            <img class="logo" src="/assets/OwnedbyBlackChatBack.png">
        </div>
        <div>
            <mat-icon class="exitButton" (click)="close()">close</mat-icon>
        </div>
    </div>
    <div style="text-align: center;">
        <div class="ghost"></div>
        <h2 class="fontOrange">Device Results</h2>
        <h3 class="font">Device : {{ quickCheckData.name }}</h3>
        <h3 class="font">Device Status : {{ quickCheckData.deviceState }}</h3>
        <h3 class="font">Blacklist Status : {{ quickCheckData.blacklistStatus }}</h3>

        <div class="ghost"></div>
        <div class="ghost"></div>

        <button mat-flat-button color="primary" class="radius" (click)="initiateChat()"  style="text-align:center;">Contact Seller</button>

    </div>
</div>