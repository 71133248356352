import { AfterViewInit, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { } from 'googlemaps';



@Component({
  selector: 'app-gmaps',
  templateUrl: './gmaps.component.html',
  styleUrls: ['./gmaps.component.css']
})
export class GmapsComponent implements OnInit, AfterViewInit {


  @ViewChild('map') mapElement: any;
  map!: google.maps.Map;
  tap!: google.maps.MapMouseEvent;
  myLatlng = { lat: -25.363, lng: 131.044 };
  marker = new google.maps.Marker({
    position: this.myLatlng,
    
    title: "Click to zoom",
  });



  constructor() {

  }

  ngOnInit(): void {



  }
  ngAfterViewInit() {
    const mapProperties = {
      center: new google.maps.LatLng(-25.363,  131.044),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);

   
    

    
  }


  whero(click: any) {

    this.marker.addListener("click", () => {
     
      this.marker.getPosition() as google.maps.LatLng;
      console.log(google.maps.LatLng)

    });

  }

  



}
