import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Device } from 'src/app/interfaces/Device';
import { ComsServiceService } from '../services/coms-service.service';
import { DeviceStateService } from '../services/device-state.service';
import { StolenideviceComponent } from '../stolenidevice/stolenidevice.component';


interface state {
  description: string;
}

@Component({
  selector: 'app-device-info-dialog',
  templateUrl: './device-info-dialog.component.html',
  styleUrls: ['./device-info-dialog.component.css']
})
export class DeviceInfoDialogComponent implements OnInit {

  sessionId!: Observable<string>;

  modules!: string[];

  deviceStateForm = this.fb.group({
    stateSubmitted: new FormControl('', [Validators.required]),
  })
  stateControl = new FormControl('', Validators.required)
  selectFormControl = new FormControl('', Validators.required)

  public deviceInfoData: Device;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Device,
    private dialogRef: MatDialogRef<DeviceInfoDialogComponent>,
    private dialog: MatDialog,
    public stateService: DeviceStateService,
    public comService: ComsServiceService,
    private route: ActivatedRoute,

  ) {
    this.deviceInfoData = data;
  }

  ngOnInit(): void {
    this.sessionId = this.route
      .queryParamMap
      .pipe(map(params => params.get('session_id') || 'None'));
  }

  close() {
    this.dialog.closeAll();
  }


  states: state[] = [
    { description: 'Active' },
    { description: 'For Sale' },
    { description: 'Lost' },
    { description: 'Stolen' },

  ];


  onStateChange() {

    var newState: string = this.deviceStateForm.value.stateSubmitted;
    var documentId: string = this.deviceInfoData.id;
    if (newState === "Stolen") {
      this.dialog.open(StolenideviceComponent, {
        data: {
          state: this.deviceStateForm.value.stateSubmitted,
          id: this.deviceInfoData.id,
        },
      })
    }
    if (newState === "Lost") {
      this.stateService.updateState(newState, documentId)
      this.comService.statusChangedToLost()
    }
    if (newState === "For Sale") {
      this.stateService.updateState(newState, documentId)
      this.comService.statusChangedToForSale()
    }

    if (newState === "Active") {
      this.stateService.updateState(newState, documentId)
      this.comService.statusChangedToActive()
    }


  }

}
