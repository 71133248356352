import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class DeviceStateService {

  constructor(
    private firestore: AngularFirestore,
    public dialog: MatDialog,
  ) { }


  updateState(newState: string, documentId: string) {

    this.firestore.collection("products").doc(documentId).update({
      state: newState,
    })
      .then(function () {
        console.log("deviceSstate Changed: ", newState);

      })
      this.dialog.closeAll();

  }

}
