import { Injectable } from '@angular/core';
import { Coms } from 'src/app/interfaces/coms';
import { MatDialog } from '@angular/material/dialog';
import { ComsDialogComponent } from '../coms-dialog/coms-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ComsServiceService {

  constructor(
    public dialog: MatDialog,
  ) { }



  alertTemplate() {

    var head: string = "Default Alert Heading (optional)"
    var content: string = "Default Alert Content that will be displayed giving some context of situation and informs user."

    console.log();
    this.dialog.open(ComsDialogComponent, {
      data: {
        comsHead: head,
        comsContent: content,
      },
    })
  }


  statusChangedToActive() {

    var head: any = null
    var content: string = "Your device status has been changed to Active."

    console.log();
    this.dialog.open(ComsDialogComponent, {
      data: {
        comsHead: head,
        comsContent: content,
      },
    })
  }


  statusChangedToForSale() {

    var head: any = null
    var content: string = "Your device status has been changed to For Sale."

    console.log();
    this.dialog.open(ComsDialogComponent, {
      data: {
        comsHead: head,
        comsContent: content,
      },
    })
  }


  statusChangedToLost() {

    var head: any = null
    var content: string = "Your device status has been changed to Lost."

    console.log();
    this.dialog.open(ComsDialogComponent, {
      data: {
        comsHead: head,
        comsContent: content,
      },
    })
  }

  imeiAlreadyInUse() {

    var head: any = "IMEI already registered"
    var content: string = "Please make sure number is valid and entered correctly"

    console.log();
    this.dialog.open(ComsDialogComponent, {
      data: {
        comsHead: head,
        comsContent: content,
      },
    })

  }












}
