<div class="accordionMaster">
  <div class="instanceWrap" (click)="toggleVisible1()">
    <div class="headerAccordian">
      <div class="furtherHeader">
        <mat-icon class="iconColor1">list</mat-icon>
        <p class="pHeader">&nbsp;&nbsp;Complete Register</p>
      </div>
      <mat-icon *ngIf="visible1 === false" class="iconDown">expand_more</mat-icon>
      <mat-icon *ngIf="visible1 === true" class="iconDown">expand_less</mat-icon>
    </div>
    <div id="footerAccordian1">
      <p class="pDescription">
        A full register of your appliances & devices, with their unique
        identities (IMEI/Serial Number)
      </p>
    </div>
  </div>

  <div class="instanceWrap" (click)="toggleVisible2()">
    <div class="headerAccordian">
      <div class="furtherHeader">
        <mat-icon class="iconColor2">devices</mat-icon>

        <p class="pHeader">&nbsp;&nbsp;Asset Allocation</p>
      </div>

      <mat-icon *ngIf="visible2 === false" class="iconDown">expand_more</mat-icon>
      <mat-icon *ngIf="visible2 === true" class="iconDown">expand_less</mat-icon>
    </div>

    <div id="footerAccordian2">
      <p class="pDescription">
        Assign specific assets to users. E.g. Businesses assigning company
        cellphones/laptops to employees. Or a device from a parent to a
        dependent
      </p>
    </div>
  </div>

  <div class="instanceWrap" (click)="toggleVisible3()">
    <div class="headerAccordian">
      <div class="furtherHeader">
        <mat-icon class="iconColor3">search</mat-icon>
        <p class="pHeader">&nbsp;&nbsp;QuickCheck</p>
      </div>
      <mat-icon *ngIf="visible3 === false" class="iconDown">expand_more</mat-icon>
      <mat-icon *ngIf="visible3 === true" class="iconDown">expand_less</mat-icon>
    </div>

    <div id="footerAccordian3">
      <p class="pDescription">
        A search engine that retrieves device specific information including
        specifications, and the Digi-Tag status applied by the owner. Personal
        information of the owner is always kept confidential
      </p>
    </div>
  </div>

  <div class="instanceWrap" (click)="toggleVisible4()">
    <div class="headerAccordian">
      <div class="furtherHeader">
        <mat-icon class="iconColor4"> local_offer</mat-icon>
        <p class="pHeader">&nbsp;&nbsp;Digi-Tags</p>
      </div>
      <mat-icon *ngIf="visible4 === false" class="iconDown">expand_more</mat-icon>
      <mat-icon *ngIf="visible4 === true" class="iconDown">expand_less</mat-icon>
    </div>

    <div id="footerAccordian4">
      <p class="pDescription">
        Owners only can apply status labels (such as Active | For-Sale | Lost |
        Stolen. These statuses are publicly accessible when an item's unique
        identity is used in a QuickCheck
      </p>
    </div>
  </div>

  <div class="instanceWrap" (click)="toggleVisible5()">
    <div class="headerAccordian">
      <div class="furtherHeader">
        <mat-icon class="iconColor1"> forum</mat-icon>
        <p class="pHeader">&nbsp;&nbsp;ChatGateway</p>
      </div>
      <mat-icon *ngIf="visible5 === false" class="iconDown">expand_more</mat-icon>
      <mat-icon *ngIf="visible5 === true" class="iconDown">expand_less</mat-icon>
    </div>

    <div id="footerAccordian5">
      <p class="pDescription">
        Connect with the listing owner, through our secure ChatGateway to
        authenticate that you are buying from the rightful owner, and initiate
        transfer of ownership of the devices profile
      </p>
    </div>
  </div>

  <div class="instanceWrap" (click)="toggleVisible6()">
    <div class="headerAccordian">
      <div class="furtherHeader">
        <mat-icon class="iconColor2">dashboard</mat-icon>
        <p class="pHeader">&nbsp;&nbsp;Dashboards</p>
      </div>
      <mat-icon *ngIf="visible6 === false" class="iconDown">expand_more</mat-icon>
      <mat-icon *ngIf="visible6 === true" class="iconDown">expand_less</mat-icon>
    </div>

    <div id="footerAccordian6">
      <p class="pDescription">
        Powerful dashboards listing all items linked to the owner (including
        those assigned to users. Owners can enjoy a summary of all
      </p>
    </div>
  </div>

  <div class="instanceWrap" (click)="toggleVisible7()">
    <div class="headerAccordian">
      <div class="furtherHeader">
        <mat-icon class="iconColor3">check_circle_outline</mat-icon>
        <p class="pHeader">&nbsp;&nbsp;Device Validation</p>
      </div>
      <mat-icon *ngIf="visible7 === false" class="iconDown">expand_more</mat-icon>
      <mat-icon *ngIf="visible7 === true" class="iconDown">expand_less</mat-icon>
    </div>

    <div id="footerAccordian7">
      <p class="pDescription">
        Validate whether a device was previously blacklisted or if it’s a clone
        or counterfeit against the worlds largest TAC database
      </p>
    </div>
  </div>

  <div class="instanceWrap" (click)="toggleVisible8()">
    <div class="headerAccordian">
      <div class="furtherHeader">
        <mat-icon class="iconColor4">swap_horizontal_circle</mat-icon>
        <p class="pHeader">&nbsp;&nbsp;Authenticate & Transfer</p>
      </div>
      <mat-icon *ngIf="visible8 === false" class="iconDown">expand_more</mat-icon>
      <mat-icon *ngIf="visible8 === true" class="iconDown">expand_less</mat-icon>
    </div>

    <div id="footerAccordian8">
      <p class="pDescription">
        Owner authentication & transfer of ownership of devices digital profile
        from seller to buyer
      </p>
    </div>
  </div>

  <div class="instanceWrap" (click)="toggleVisible9()">
    <div class="headerAccordian">
      <div class="furtherHeader">
        <mat-icon class="iconColor1">group_work</mat-icon>
        <p class="pHeader">&nbsp;&nbsp;Company Portal</p>
      </div>
      <mat-icon *ngIf="visible9 === false" class="iconDown">expand_more</mat-icon>
      <mat-icon *ngIf="visible9 === true" class="iconDown">expand_less</mat-icon>
    </div>

    <div id="footerAccordian9">
      <p class="pDescription">
        Custom portal with company colors & logo. Role based access control for
        sub accounts with trackable change logs e.g. immediate alert when user
        changes digi-tag
      </p>
    </div>
  </div>
</div>
