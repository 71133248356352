import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { QuickcheckDialogComponent } from '../dialogs/quickcheck-dialog/quickcheck-dialog.component';
import { RegisterDeviceDialogComponent } from '../dialogs/register-device-dialog/register-device-dialog.component';
import { ResetPasswordDialogComponent } from '../dialogs/reset-password-dialog/reset-password-dialog.component';
import { UpdateDeviceStateDialogComponent } from '../dialogs/update-device-state-dialog/update-device-state-dialog.component';
import { AuthenticationService } from '../services/authentication.service';
import {
  ComponentPortal,
  DomPortal,
  Portal,
  TemplatePortal,
} from '@angular/cdk/portal';
import {
  AfterViewInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ElementRef,
} from '@angular/core';
import { SignInPageComponent } from '../sign-in-page/sign-in-page.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FormBuilder, FormControl } from '@angular/forms';
import { Observable, Timestamp } from 'rxjs';
import { finalize, map, shareReplay, tap, timestamp } from 'rxjs/operators';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { UserService } from '../services/user.service';
import { Notifications } from '../interfaces/notifications';
import { NotificationInfoDialogComponent } from '../notification-info-dialog/notification-info-dialog.component';
//import { ChatInviteDialogComponent } from '../chat-invite-dialog/chat-invite-dialog.component';
import { JsonpClientBackend } from '@angular/common/http';
import { NotificationIfInviteComponent } from '../notification-info-dialog/notification-if-invite/notification-if-invite.component';
import { WebcamImage } from 'ngx-webcam';
import { Time } from '@angular/common';




interface Names {
  firstName: string;
  lastName: string;
}

interface timeStampLastSeen {
  lastNotificationCheck: any;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {

  sessionId!: Observable<string>;
  token!: Observable<string>;

  
  backb1: boolean = false;
  backb2: boolean = false;
  backb3: boolean = false;
  backb4: boolean = false;

  descriptLink1: any;
  descriptLink2: any;
  descriptLink3: any;
  descriptLink4: any;

  @ViewChild('myDiv')
  myDiv!: ElementRef<HTMLElement>;

  public newNotification: number = 0;
  public badgeTally: number = 0;

  public notificationsBoxVisible: boolean = true;
  sideNavBoxVisible: boolean = true;
  public overlayVisible: boolean = true;
  overlayVisible2: boolean = true;
  public showLoader: boolean = true;
  public splash: boolean = false;

  public notificationCollection:
    | AngularFirestoreCollection<Notifications>
    | undefined;
  public notification: Observable<Notifications[]> | undefined;
  notificationRef: any = this.userService.getUserLoggedIn();

  public currentUserArray: any[] = [];

  notificationCheckpoint: any;
  autoiemi!: any;








  name: any;
  secondName: any;
  emailAddress: any;

  storageKeyString = localStorage.getItem('userId');
  public mm: number = 0;


  constructor(
    private authService: AuthenticationService,
    public dialog: MatDialog,
    private vf: ViewContainerRef,
    private breakpointObserver: BreakpointObserver,
    private fb: FormBuilder,
    public router: Router,
    
    private firestore: AngularFirestore,
    private userService: UserService,
    private route: ActivatedRoute,
  ) {




    console.log("at least you tried", )




    this.notificationCollection = this.firestore
      .collection('messages')
      .doc(this.notificationRef)
      .collection('notifications') 




    this.notification = this.notificationCollection.snapshotChanges().pipe(
      map((actions: any[]) => {
        var quickTally = 0;


        return actions.map((a) => {




          var data = a.payload.doc.data() as Notifications;
          data.id = a.payload.doc.id;
          var tallFlag = data.date;





          if (this.notificationCheckpoint < tallFlag) {
            quickTally = quickTally + 1;
            //console.log('newNotifi', quickTally);
          }
          //console.log(data);
          this.badgeTally = quickTally;

          return { ...data };
        });
      })

    );





    this.showLoader = false;

    console.log(this.badgeTally, 'For badge');
  }

  ngOnInit(): void {


    // Capture the session ID if available
    this.sessionId = this.route
      .queryParamMap
      .pipe(map(params => params.get('session_id') || 'None'),);

    // Capture the fragment if available
    this.token = this.route
      .fragment
      .pipe(map(fragment => fragment || 'None'));






    this.descriptLink1 = document.getElementById('sideone');
    this.descriptLink2 = document.getElementById('sidetwo');
    this.descriptLink3 = document.getElementById('sidethree');
    this.descriptLink4 = document.getElementById('sidefour');

    this.authService.checkState();
    this.authService.buildUserDetails(this.currentUserArray);


    var sss;

    this.firestore.collection('lastSeen').doc(this.notificationRef).valueChanges().subscribe((sss: any) => {
      var sata = sss as timeStampLastSeen;

      console.log(sss, 'ssssssssss', sata);
      this.notificationCheckpoint = sata.lastNotificationCheck
    });

    this.route.queryParams.subscribe(params => {
      this.autoiemi = params['autoiemi'];
    });






    console.log(this.currentUserArray, this.notificationCheckpoint, this.autoiemi);
  }

  imeiURL(email: string, password: string, autoiemi: any) {
    this.authService.continueFlag = true;
  }

  revealPage() {
    this.splash = !this.splash;
  }

  qCheckForm = this.fb.group({
    iemiSubmitted: new FormControl(''),
  });

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  openResetPassword() {
    this.dialog.open(ResetPasswordDialogComponent);
  }

  openUserQuickCheck() {
    this.dialog.open(QuickcheckDialogComponent);
  }

  zeroNew() {

  }

  openMaps() { }

  signOut() {
    this.authService.signOut();
  }

  onqCheck() {
    console.log(this.qCheckForm.value.iemiSubmitted);
  }

  toggleNotificationsBox() {
    this.badgeTally = 0;

    this.firestore
      .collection('lastSeen')
      .doc(this.notificationRef)
      .set({
        lastNotificationCheck: new Date(),
      })

    this.firestore.collection('lastSeen').doc(this.notificationRef).get().toPromise().then((doc) => {
      if (doc.exists) {
        console.log("Document data:", doc.data());
        this.notificationCheckpoint = doc.data();



      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    }).catch((error: any) => {
      console.log("Error getting document:", error);
    });;
    this.notificationsBoxVisible = !this.notificationsBoxVisible;
    this.overlayVisible = !this.overlayVisible;





  }

  zeroNotifications() {
    this.newNotification = 0;
  }

  togglesidenav() {
    this.sideNavBoxVisible = !this.sideNavBoxVisible;
    this.overlayVisible2 = !this.overlayVisible2;
  }

  viewNotification(notification: Notifications) {
    console.log(notification);

    if (notification.type === 'ChatInvite') {
      var inviteSent: Boolean = true;

      console.log(inviteSent);

      this.dialog.open(NotificationIfInviteComponent, {
        data: {
          date: notification.date,
          message: notification.message,
          id: notification.id,
          type: notification.type,
          device: notification.device,
          flag: inviteSent,
        },
      });
      console.log(inviteSent);
      this.router.navigate(['chat']);
    } else {
      this.dialog.open(NotificationInfoDialogComponent, {
        data: {
          date: notification.date,
          message: notification.message,
          id: notification.id,
          type: notification.type,
          device: notification.device,
        },
      });
    }
  }

  homeActive() {
    this.backb1 = true;
    this.backb2 = false;
    this.backb3 = false;
    this.backb4 = false;
    console.log(this.backb1);
    if (this.backb1 === true) {
      this.descriptLink1.style.background = 'rgb(207, 210, 255)';
      this.descriptLink2.style.background = 'rgba(255, 255, 255, 0)';
      this.descriptLink3.style.background = 'rgba(255, 255, 255, 0)';
      this.descriptLink4.style.background = 'rgba(255, 255, 255, 0)';

    } else {
      this.descriptLink1.style.background = 'rgba(255, 255, 255, 0)';
    }
  }

  deviceActive() {
    this.backb1 = false;
    this.backb2 = true;
    this.backb3 = false;
    this.backb4 = false;
    console.log(this.backb1);
    if (this.backb2 === true) {
      this.descriptLink1.style.background = 'rgba(255, 255, 255, 0)';
      this.descriptLink2.style.background = 'rgb(207, 210, 255)';
      this.descriptLink3.style.background = 'rgba(255, 255, 255, 0)';
      this.descriptLink4.style.background = 'rgba(255, 255, 255, 0)';
    } else {
      this.descriptLink2.style.background = 'rgba(255, 255, 255, 0)';
    }
  }
  chatActive() {
    this.backb1 = false;
    this.backb2 = false;
    this.backb3 = true;
    this.backb4 = false;
    console.log(this.backb1);
    if (this.backb3 === true) {
      this.descriptLink1.style.background = 'rgba(255, 255, 255, 0)';
      this.descriptLink2.style.background = 'rgba(255, 255, 255, 0)';
      this.descriptLink3.style.background = 'rgb(207, 210, 255)';
      this.descriptLink4.style.background = 'rgba(255, 255, 255, 0)';
    } else {
      this.descriptLink3.style.background = 'rgba(255, 255, 255, 0)';
    }
  }
  profileActive() {
    this.backb1 = false;
    this.backb2 = false;
    this.backb3 = false;
    this.backb4 = true;
    console.log(this.backb1);
    if (this.backb4 === true) {
      this.descriptLink1.style.background = 'rgba(255, 255, 255, 0)';
      this.descriptLink2.style.background = 'rgba(255, 255, 255, 0)';
      this.descriptLink3.style.background = 'rgba(255, 255, 255, 0)';
      this.descriptLink4.style.background = 'rgb(207, 210, 255)';
    } else {
      this.descriptLink4.style.background = 'rgba(255, 255, 255, 0)';
    }
  }
}
