<div class="exitButtonRight">
   
    <div>
        <img class="cardImg" src="/assets/OwnedbyBlackChatBack.png">
    </div>
    <div>
        <mat-icon class="exitButton" (click)="close()">close</mat-icon>
    </div>
</div>

<div class="container" style="text-align: center;">
    <div class="ghost2"></div>
    <h2 class="fontMullish bolder">Reset Password</h2>
    <p>Enter the account email of forgotten password.</p>
    <p>A link will be emailed to your inbox,<br> follow the link to create a new password.</p>

    <form [formGroup]="ForgotPassForm" (ngSubmit)="onForgotPass(ForgotPassForm.value)">
        <mat-form-field class="formFeild" appearance="legacy">
            <mat-label>Email</mat-label>
            <input type="email" matInput class="form-control" formControlName="passwordResetEmail" placeholder="Enter Email"
                required>
        </mat-form-field>
        <div>
            <button mat-flat-button color="primary"  class="radius"  type="submit">Send Email</button>

        </div>
        
    </form>
</div>