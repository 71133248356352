import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accordian',
  templateUrl: './accordian.component.html',
  styleUrls: ['./accordian.component.css'],
})
export class AccordianComponent implements OnInit {
  visible1: boolean = false;
  visible2: boolean = false;
  visible3: boolean = false;
  visible4: boolean = false;
  visible5: boolean = false;
  visible6: boolean = false;
  visible7: boolean = false;
  visible8: boolean = false;
  visible9: boolean = false;

  descriptLength1: any;
  descriptLength2: any;
  descriptLength3: any;
  descriptLength4: any;
  descriptLength5: any;
  descriptLength6: any;
  descriptLength7: any;
  descriptLength8: any;
  descriptLength9: any;

  constructor() {}

  ngOnInit(): void {
    this.descriptLength1 = document.getElementById('footerAccordian1');
    this.descriptLength2 = document.getElementById('footerAccordian2');
    this.descriptLength3 = document.getElementById('footerAccordian3');
    this.descriptLength4 = document.getElementById('footerAccordian4');
    this.descriptLength5 = document.getElementById('footerAccordian5');
    this.descriptLength6 = document.getElementById('footerAccordian6');
    this.descriptLength7 = document.getElementById('footerAccordian7');
    this.descriptLength8 = document.getElementById('footerAccordian8');
    this.descriptLength9 = document.getElementById('footerAccordian9');
  }

  toggleVisible1() {
    this.visible1 = !this.visible1;
    this.visible2 = false;
    this.visible3 = false;
    this.visible4 = false;
    this.visible5 = false;
    this.visible6 = false;
    this.visible7 = false;
    this.visible8 = false;
    this.visible9 = false;
    console.log(this.visible1);
    if (this.visible1 === true) {
      this.descriptLength1.style.height = '3cm';
    } else {
      this.descriptLength1.style.height = '0px';
    }
  }

  toggleVisible2() {
    this.visible1 = false;
    this.visible2 = !this.visible2;
    this.visible3 = false;
    this.visible4 = false;
    this.visible5 = false;
    this.visible6 = false;
    this.visible7 = false;
    this.visible8 = false;
    this.visible9 = false;
    console.log(this.visible1);
    if (this.visible2 === true) {
      this.descriptLength2.style.height = '3cm';
    } else {
      this.descriptLength2.style.height = '0px';
    }
  }
  toggleVisible3() {
    this.visible1 = false;
    this.visible2 = false;
    this.visible3 = !this.visible3;
    this.visible4 = false;
    this.visible5 = false;
    this.visible6 = false;
    this.visible7 = false;
    this.visible8 = false;
    this.visible9 = false;
    console.log(this.visible1);
    if (this.visible3 === true) {
      this.descriptLength3.style.height = '3cm';
    } else {
      this.descriptLength3.style.height = '0px';
    }
  }
  toggleVisible4() {
    this.visible1 = false;
    this.visible2 = false;
    this.visible3 = false;
    this.visible4 = !this.visible4;
    this.visible5 = false;
    this.visible6 = false;
    this.visible7 = false;
    this.visible8 = false;
    this.visible9 = false;
    console.log(this.visible1);
    if (this.visible4 === true) {
      this.descriptLength4.style.height = '3cm';
    } else {
      this.descriptLength4.style.height = '0px';
    }
  }
  toggleVisible5() {
    this.visible1 = false;
    this.visible2 = false;
    this.visible3 = false;
    this.visible4 = false;
    this.visible5 = !this.visible5;
    this.visible6 = false;
    this.visible7 = false;
    this.visible8 = false;
    this.visible9 = false;
    console.log(this.visible1);
    if (this.visible5 === true) {
      this.descriptLength5.style.height = '3cm';
    } else {
      this.descriptLength5.style.height = '0px';
    }
  }
  toggleVisible6() {
    this.visible1 = false;
    this.visible2 = false;
    this.visible3 = false;
    this.visible4 = false;
    this.visible5 = false;
    this.visible6 = !this.visible6;
    this.visible7 = false;
    this.visible8 = false;
    this.visible9 = false;
    console.log(this.visible1);
    if (this.visible6 === true) {
      this.descriptLength6.style.height = '3cm';
    } else {
      this.descriptLength6.style.height = '0px';
    }
  }
  toggleVisible7() {
    this.visible1 = false;
    this.visible2 = false;
    this.visible3 = false;
    this.visible4 = false;
    this.visible5 = false;
    this.visible6 = false;
    this.visible7 = !this.visible7;
    this.visible8 = false;
    this.visible9 = false;
    console.log(this.visible1);
    if (this.visible7 === true) {
      this.descriptLength7.style.height = '3cm';
    } else {
      this.descriptLength7.style.height = '0px';
    }
  }
  toggleVisible8() {
    this.visible1 = false;
    this.visible2 = false;
    this.visible3 = false;
    this.visible4 = false;
    this.visible5 = false;
    this.visible6 = false;
    this.visible7 = false;
    this.visible8 = !this.visible8;
    this.visible9 = false;
    console.log(this.visible1);
    if (this.visible8 === true) {
      this.descriptLength8.style.height = '3cm';
    } else {
      this.descriptLength8.style.height = '0px';
    }
  }
  toggleVisible9() {
    this.visible1 = false;
    this.visible2 = false;
    this.visible3 = false;
    this.visible4 = false;
    this.visible5 = false;
    this.visible6 = false;
    this.visible7 = false;
    this.visible8 = false;
    this.visible9 = !this.visible9;
    console.log(this.visible1);
    if (this.visible9 === true) {
      this.descriptLength9.style.height = '3cm';
    } else {
      this.descriptLength9.style.height = '0px';
    }
  }
}
