import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-reg-succes',
  templateUrl: './user-reg-succes.component.html',
  styleUrls: ['./user-reg-succes.component.css']
})
export class UserRegSuccesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
