import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';

import { QuickCheckResult } from 'src/app/interfaces/QuickCheckResult';
import { RawMessages } from 'src/app/interfaces/raw-messages';
import { UserService } from 'src/app/services/user.service';
import { DashqcheckresultComponent } from '../dashqcheckresult.component';
@Component({
  selector: 'app-qcheck-if-for-sale-result',
  templateUrl: './qcheck-if-for-sale-result.component.html',
  styleUrls: ['./qcheck-if-for-sale-result.component.css']
})
export class QcheckIfForSaleResultComponent implements OnInit {

  notificationRef: any = this.userService.getUserLoggedIn();

  public quickCheckData: QuickCheckResult;

  emailForm = this.fb.group({
    emailSubmitted: new FormControl('', [Validators.required, Validators.email]),
  })

  public hasError = (controlName: string, errorName: string) => {
    return this.emailForm.controls[controlName].hasError(errorName);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: QuickCheckResult,
    private fb: FormBuilder,
    private firestore: AngularFirestore,
    private dialogRef: MatDialogRef<DashqcheckresultComponent>,
    public dialog: MatDialog,
    private userService: UserService
  ) {
    this.quickCheckData = data;
  }

  ngOnInit(): void {
    console.log("date : " + JSON.stringify(this.data));
  }

  email(quickCheckData: QuickCheckResult) {
    console.log("Emailing : " + JSON.stringify(quickCheckData) + " to " + this.emailForm.value.emailSubmitted);
    this.firestore.collection("quickcheckEmails").add({
      emailAddress: this.emailForm.value.emailSubmitted,
      quickCheckKey: quickCheckData.searchId,
      date: new Date()
    })
      .then((docRef) => {
        console.log("Email doc created");
        this.dialogRef.close();
      })
  }

  close() {
    this.dialog.closeAll();
  }


  initiateChat() {


    var s: string = "web"
    var m: string = "Someone is interested in you device that is listed for sale"


    this.firestore.collection("chatInvites").add({

      fromUser: this.notificationRef,
      //message: m,
      //source: s,
      toUser: this.quickCheckData.deviceUser,
      date: new Date(),
      device: this.quickCheckData.device,
    })
      .then((docRef) => {
        console.log("rawmessage doc created");
        this.dialogRef.close();
      })

  }
}
