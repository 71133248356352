<div style="text-align: center;">

    <div class="exitButtonRight">
        <div>
            <img class="cardImg" src="/assets/OwnedbyBlackChatBack.png">
        </div>
        <div>
            <mat-icon class="exitButton" (click)="close()">close</mat-icon>
        </div>
    </div>
    <div class="ghost"></div>
    <h1 class="orange">Register User</h1>









    <form [formGroup]="registerForm" (ngSubmit)="onRegisterUser()">
        <div style="text-align: center; font-size: 12px">
            <div>
                <mat-form-field class="forms form-group" appearance="legacy">
                    <mat-label class="fontMullish">First Name</mat-label>
                    <input type="text" matInput class="form-control fontMullish" formControlName="firstNameSubmitted"
                        placeholder="Enter First Name" required>
                </mat-form-field>
            </div>
            <div class="ghost"></div>
            <div>
                <mat-form-field class="forms form-group" appearance="legacy">
                    <mat-label class="fontMullish">Last Name</mat-label>
                    <input type="text" matInput class="form-control fontMullish" formControlName="lastNameSubmitted"
                        placeholder="Enter Last Name" required>
                </mat-form-field>
            </div>
            <div class="ghost"></div>
            <div>
                <mat-form-field class="forms form-group" appearance="legacy">
                    <mat-label class="fontMullish">Email</mat-label>
                    <input type="email" matInput class="form-control fontMullish" formControlName="emailSubmitted"
                        placeholder="Enter Email" required>
                </mat-form-field>
            </div>
            <div class="ghost"></div>
            <div>
                <mat-form-field class="forms form-group" appearance="legacy">
                    <mat-label class="fontMullish">New Password</mat-label>
                    <input type="password" matInput class="form-control fontMullish" formControlName="passwordSubmitted"
                        placeholder="Enter new password" required>
                </mat-form-field>
            </div>
            <div class="ghost"></div>
            <div>
                <mat-form-field class="forms form-group" appearance="legacy">
                    <mat-label class="fontMullish">Confirm new Password</mat-label>
                    <input type="password" matInput class="form-control fontMullish"
                        formControlName="confirmPasswordSubmitted" placeholder="Confirm new Password" required>
                </mat-form-field>
            </div>
            <div class="ghost"></div>
            <div class="ghost"></div>


            <p>
                <mat-checkbox (click)="toggleClass()" class="boxMargin"></mat-checkbox>By Registering you agree
                with
                our <a href="termsService">Terms of Service</a> and our <a href="PrivacyPolicy">Privacy Policy</a>.
            </p>
            <button mat-flat-button [class.active]="classApplied" class="but"  type="submit">Register</button>
            <button mat-flat-button [class.active]="classApplied2" type="button" (click)="agree()" class="dis">Register</button>
        </div>
    </form>
</div>