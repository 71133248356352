<div class="wider" style="text-align: center">
    <div class="exitButtonRight">
      <div>
        <img class="logo" src="/assets/OwnedbyBlackChatBack.png" />
      </div>
      <div>
        <mat-icon class="exitButton" (click)="close()">close</mat-icon>
      </div>
    </div>
  
    <div class="infoBlock">
      <div>
        <div class="inRow">
          <p class="boldDescription">
            {{ notificationInfoData.date.toDate() | date: "dd MMM yyyy hh:mm" }}
          </p>
        </div>
        <div class="inRow">
          <p class="filledValues">{{ notificationInfoData.message }}</p>
        </div>
      </div>
    </div>
    <button mat-flat-button color="primary" class="radius" (click)="onGoToChat()">
      Chat-Gateway
    </button>
    <div class="ghost"></div>
    <div class="ghost"></div>
  </div>