import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Notifications } from '../interfaces/notifications';

@Component({
  selector: 'app-notification-info-dialog',
  templateUrl: './notification-info-dialog.component.html',
  styleUrls: ['./notification-info-dialog.component.css']
})
export class NotificationInfoDialogComponent implements OnInit {

  public notificationInfoData: Notifications;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Notifications,
    private dialogRef: MatDialogRef<NotificationInfoDialogComponent>,
    private dialog: MatDialog,
  ) {
    this.notificationInfoData = data;
   }

  ngOnInit(): void {
  }

  close() {
    this.dialog.closeAll();
  }

}
