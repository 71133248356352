import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { SignUpDialogComponent } from "../dialogs/sign-up-dialog/sign-up-dialog.component";
import { ResetPasswordDialogComponent } from '../dialogs/reset-password-dialog/reset-password-dialog.component';
import { UserService } from '../services/user.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ComsServiceService } from '../services/coms-service.service';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { MatMenuTrigger } from '@angular/material/menu';
import { OverlaysService } from '../services/overlays.service';
import * as firebase from 'firebase';
import { EmailNotVerifiedComponent } from '../email-not-verified/email-not-verified.component';
import { EventEmitter, Injectable, NgZone, Output } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/auth';

import { DashboardComponent } from '../dashboard/dashboard.component';

import 'firebase/firestore';
import { BehaviorSubject, } from 'rxjs';


import { UserRegSuccesComponent } from '../user-reg-succes/user-reg-succes.component';
import { AdsenseModule } from 'ng2-adsense';

const navigationExtras: NavigationExtras = {
  queryParamsHandling: 'preserve',
  preserveFragment: true
};



@Component({
  selector: 'app-sign-in-alt',
  templateUrl: './sign-in-alt.component.html',
  styleUrls: ['./sign-in-alt.component.css']
})
export class SignInAltComponent implements OnInit {

  autoiemi!: any;


  loginForm = this.fb.group({
    email: new FormControl(''),
    password: new FormControl(''),
  });














  constructor(
    //private afAuth: AngularFireAuth,
    public dialog: MatDialog,
    private authService: AuthenticationService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    public overlayServ: OverlaysService,
    public firebase: AngularFireAuth,
    private userService: UserService,
    private route: ActivatedRoute,


    //private signInForm: FormGroup,
  ) {








  }


  ngOnInit() {





    /*window.addEventListener('load', (event) => {
      console.log('page is fully loaded');

      this.overlayShowing = false;

      this.revealPage();
    });*/



  }

  

  revealPage() {

  }

  signIn(email: string, password: string) {
    console.log('about to sign in existing user', email, password);
    this.firebase

      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed in

        if (userCredential.user?.emailVerified === true) {
          this.userService.setUserLoggedIn(userCredential.user.uid, email);
          this.spinner.hide();
          this.router.navigate(['dashboard'], navigationExtras);
        } else {
          this.authService.sendVerMail();
          this.spinner.hide();
          this.dialog.open(EmailNotVerifiedComponent);
        }
        // ...
      })
      .catch((error) => {
        this.spinner.hide();
        window.alert(error.message);
        var errorCode = error.code;
        var errorMessage = error.message;
      });
  }








  onSignIn() {
    this.spinner.show();
    var email: string = this.loginForm.value.email;
    var password: string = this.loginForm.value.password;
    this.authService.imeiURL(email, password, this.autoiemi);
    this.router.navigate(['dashboard']);

  }



  seeUser() {
    this.authService.getCurrentUser()
  }

  passwordReset() {

  }

  openRegisterDialog() {
    this.dialog.open(SignUpDialogComponent);
  }

  openResetPassword() {
    this.dialog.open(ResetPasswordDialogComponent);
  }
  googleLogin() {
    window.alert("Google in process of approving domain first before Sign-up/Sign-in functional");
  }

}
