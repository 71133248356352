<div class="inviteM">
  <p class="fontOrange">Accept Invite?</p>

  <div class="sepperate">
    <button mat-flat-button class="greenButton" (click)="onAccept()">
      Accept
    </button>

    <button class="redButton" mat-flat-button (click)="onClose()">Decline</button>
  </div>
</div>
