import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuickCheckResult } from 'src/app/interfaces/QuickCheckResult';

@Component({
  selector: 'app-quickcheck-result',
  templateUrl: './quickcheck-result.component.html',
  styleUrls: ['./quickcheck-result.component.css']
})
export class QuickcheckResultComponent implements OnInit {

  public quickCheckData: QuickCheckResult;

  emailForm = this.fb.group({
    emailSubmitted: new FormControl('', [Validators.required, Validators.email]),
  })

  public hasError = (controlName: string, errorName: string) => {
    return this.emailForm.controls[controlName].hasError(errorName);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: QuickCheckResult,
    private fb: FormBuilder,
    private firestore: AngularFirestore,
    private dialogRef: MatDialogRef<QuickcheckResultComponent>,
    public dialog: MatDialog,
  ) {
    this.quickCheckData = data;
  }

  ngOnInit(): void {
    console.log("date : " + JSON.stringify(this.data));
  }

  classApplied = true;
  classApplied2 = false;

  toggleClass() {
    this.classApplied = !this.classApplied;
    this.classApplied2 = !this.classApplied2;
  }

  agree() {
    window.alert("You have to agree with terms before registering")
  }

  email(quickCheckData: QuickCheckResult) {
    console.log("Emailing : " + JSON.stringify(quickCheckData) + " to " + this.emailForm.value.emailSubmitted);
    this.firestore.collection("quickcheckEmails").add({
      emailAddress: this.emailForm.value.emailSubmitted,
      quickCheckKey: quickCheckData.searchId,
      date: new Date()
    })
      .then((docRef) => {
        console.log("Email doc created");
        this.dialogRef.close();
      })
  }

  close() {
    this.dialog.closeAll();
  }

}
