<div class="master">
  <div class="masterFlex">
    <div
      class="leftBox"
      style="text-align: center"
      [class.hide]="visibilityInstance"
    >
      <div class="instanceHeaderControl"></div>

      <div *ngIf="showLoader" class="loader">Loading...</div>

      <div class="absy">no Messages yet</div>

      <div
        class="instance"
        (click)="onSelectInstance(chtInst)"
        *ngFor="let chtInst of chtInst | async; index as i"
      >
        <div
          *ngIf="chtInst.inviteAccepted === false && chtInst.isInvite === true"
          class="isInvite"
        >
          <div class="picForInstanceInvite"></div>
          <div class="descrption">
            <p class="bold furtherDescript">{{ chtInst.deviceName }}</p>

            <p class="furtherDescript">{{ chtInst.name }}</p>

            <p class="furtherDescript">
              {{ chtInst.date.toDate() | date: "dd MMM yyyy HH:mm" }}
            </p>
          </div>

          <div class="furtherControl">
            <p class="invFont">Chat Invite</p>
          </div>
        </div>

        <div
          *ngIf="chtInst.inviteAccepted === false && chtInst.isInvite === false"
          class="isPending"
        >
          <div class="picForInstancePending"></div>
          <div class="descrption">
            <p class="bold furtherDescript">{{ chtInst.deviceName }}</p>

            <p class="furtherDescript">{{ chtInst.name }}</p>

            <p class="furtherDescript">
              {{ chtInst.date.toDate() | date: "dd MMM yyyy HH:mm" }}
            </p>
          </div>

          <div class="furtherControl">
            <p class="invFont">Invite Pending</p>
          </div>
        </div>

        <div
          *ngIf="chtInst.isInvite === true && chtInst.inviteAccepted === true"
          class="yourDevice"
        >
          <div class="picForInstanceNorm"></div>
          <div class="descrption">
            <p class="bold furtherDescript">{{ chtInst.deviceName }}</p>

            <p class="furtherDescript">{{ chtInst.name }}</p>

            <p class="furtherDescript">
              {{ chtInst.date.toDate() | date: "dd MMM yyyy HH:mm" }}
            </p>
          </div>

          <div class="furtherControl">
            <p class="invFont">Your Device</p>
          </div>
        </div>

        <div
          *ngIf="chtInst.isInvite === false && chtInst.inviteAccepted === true"
          class="otherDevice"
        >
          <div class="picForInstanceNorm"></div>
          <div class="descrption">
            <p class="bold furtherDescript">{{ chtInst.deviceName }}</p>

            <p class="furtherDescript">{{ chtInst.name }}</p>

            <p class="furtherDescript">
              {{ chtInst.date.toDate() | date: "dd MMM yyyy HH:mm" }}
            </p>
          </div>

          <div class="furtherControl">
            <p class="invFont"></p>
          </div>
        </div>
      </div>
    </div>

    <div class="divider"></div>

    <div class="rightBox" [class.hide]="visibiltyContent">
      <div class="contentHead">
        <div class="headerRow">
          <div class="buttonHide">
            <button class="backButton" (click)="toggleVisibilty()">
              <mat-icon class="backIcon">arrow_back</mat-icon>
            </button>
          </div>
          <div></div>
          <div class="headerProfilePic"></div>
          <div class="rowAgain">
            <div>
              <p class="contentName">{{ chtHeader }}</p>
            </div>
            <div>
              <p class="whoDevice">{{ whoDevice }},{{ rmName }}</p>
            </div>
          </div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="contentContent">
        <div [class.hidden]="defaultMessage">
          <h1 class="dMessage">Select a Chat</h1>
        </div>

        <div>
          <div *ngFor="let item of chtCnt | async; index as i">
            <div *ngIf="item.fromUser === notificationRef" class="cardYou">
              <p class="mText">{{ item.message }}</p>
              <p class="mTime">
                {{ item.date.toDate() | date: "dd MMM yyyy HH:mm" }}
              </p>
            </div>
            <div *ngIf="item.fromUser !== notificationRef" class="cardHim">
              <div>
                <div>
                  <p class="mText">{{ item.message }}</p>
                  <p class="mTime">
                    {{ item.date.toDate() | date: "dd MMM yyyy HH:mm" }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="contentFoot">
        <div class="messageField">
          <form [formGroup]="messageForm" (ngSubmit)="onSendMessage()">
            <input type="text" formControlName="messageSubmitted" />
          </form>
        </div>
        <div>
          <mat-icon (click)="onSendMessage()" type="submit" class="sendIcon"
            >send</mat-icon
          >
        </div>
      </div>
    </div>
  </div>
</div>
