import { stringify } from '@angular/compiler/src/util';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CamPopupComponent } from '../cam-popup/cam-popup.component';
import { CameraComponent } from '../camera/camera.component';
import { RegisterDeviceDialogComponent } from '../dialogs/register-device-dialog/register-device-dialog.component';
import { ProfileScreen } from '../interfaces/profile-screen';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user.service';
import { ProvideEmailComponent } from './provide-email/provide-email.component';
import { ProvideSaidComponent } from './provide-said/provide-said.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent {
  public profileCollection:
    | AngularFirestoreCollection<ProfileScreen>
    | undefined;

  public profileCollection2:
    | AngularFirestoreCollection<ProfileScreen>
    | undefined;

  public usProfile: Observable<ProfileScreen[]>;

  progressTally: number = 3;

  UserArray: any[] = [];
  name: any;
  secondName: any;
  emailAddress: any;

  profileFirstName: any = 'nullo';
  profileLastName: any = 'nullo';
  profileEmail: any = 'nullo';
  profileContactNumber: any = 'nullo';
  profileSaId: any = 'nullo';

  userUID: any = this.userService.getUserLoggedIn();

  constructor(
    private firestore: AngularFirestore,
    private authService: AuthenticationService,
    public dialog: MatDialog,
    private afAuth: AngularFireAuth,
    private userService: UserService,
    public router: Router
  ) {
    this.profileCollection = this.firestore.collection('users');
    this.usProfile = this.profileCollection.snapshotChanges().pipe(
      map((actions: any[]) => {
        return actions.map((a) => {
          const data = a.payload.doc.data() as ProfileScreen;
          data.profileCollection2 = a.payload.doc.id;
          console.log(data);
          return { ...data };
        });
      })
    );

    console.log(this.usProfile);
  }

  ngOnInit() {
    this.buildProfileScreen(this.UserArray);

    this.emailAddress = localStorage.getItem('email');
    console.log(this.emailAddress);
  }

  

  onChangePassword() {
    this.authService.ForgotPassword(this.emailAddress);
  }

  buildProfileScreen(UserArray: any) {
    this.authService.populateProfileScreen(UserArray);

    var ha = this.UserArray;

    this.UserArray;

    JSON.stringify(this.UserArray);
    this.profileFirstName = this.UserArray;

    console.log('plllllll', this.UserArray, this.profileFirstName);

    console.log('plllllll', this.UserArray);

    console.log('entries in profile:');

    this.progressTally = 5;

    this.profileFirstName = this.UserArray;
    this.profileLastName = this.UserArray;
    console.log(this.profileFirstName);

    if (this.profileFirstName === 'nullo') {
      this.progressTally = this.progressTally - 1;
    }
    if (this.profileLastName === 'nullo') {
      this.progressTally = this.progressTally - 1;
    }
    if (this.profileEmail === 'nullo') {
      this.progressTally = this.progressTally - 1;
    }
    if (this.profileContactNumber === 'nullo') {
      this.progressTally = this.progressTally - 1;
    }
    if (this.profileSaId === 'nullo') {
      this.progressTally = this.progressTally - 1;
    }

    this.displayBar(this.progressTally);
  }

  openSubmittContact() {
    this.dialog.open(ProvideEmailComponent);
    this.dialog.afterAllClosed;
  }
  openCam() {
    this.dialog.open(CamPopupComponent);
  }

  openSubmittId() {
    this.dialog.open(ProvideSaidComponent);
  }

  displayBar(progressTally: number) {
    console.log(this.progressTally);
  }
}
