import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { QuickcheckResultComponent } from '../dialogs/quickcheck-result/quickcheck-result.component';
import { QuickCheckResult } from '../interfaces/QuickCheckResult';

@Component({
  selector: 'app-dashqcheckresult',
  templateUrl: './dashqcheckresult.component.html',
  styleUrls: ['./dashqcheckresult.component.css']
})
export class DashqcheckresultComponent implements OnInit {

  public quickCheckData: QuickCheckResult;

  emailForm = this.fb.group({
    emailSubmitted: new FormControl('', [Validators.required, Validators.email]),
  })

  public hasError = (controlName: string, errorName: string) => {
    return this.emailForm.controls[controlName].hasError(errorName);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: QuickCheckResult,
    private fb: FormBuilder,
    private firestore: AngularFirestore,
    private dialogRef: MatDialogRef<DashqcheckresultComponent>,
    public dialog: MatDialog,
  ) {
    this.quickCheckData = data;
  }

  ngOnInit(): void {
    console.log("date : " + JSON.stringify(this.data));
  }

  email(quickCheckData: QuickCheckResult) {
    console.log("Emailing : " + JSON.stringify(quickCheckData) + " to " + this.emailForm.value.emailSubmitted);
    this.firestore.collection("quickcheckEmails").add({
      emailAddress: this.emailForm.value.emailSubmitted,
      quickCheckKey: quickCheckData.searchId,
      date: new Date()
    })
      .then((docRef) => {
        console.log("Email doc created");
        this.dialogRef.close();
      })
  }

  close() {
    this.dialog.closeAll();
  }

}
