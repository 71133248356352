import { BrowserModule } from '@angular/platform-browser';
import { /*APP_INITIALIZER*/ CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

//var firebase = require("firebase");

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';




import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { OverlayModule } from '@angular/cdk/overlay';



import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignInPageComponent } from './sign-in-page/sign-in-page.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SignUpDialogComponent } from './dialogs/sign-up-dialog/sign-up-dialog.component';
import { ResetPasswordDialogComponent } from './dialogs/reset-password-dialog/reset-password-dialog.component';
import { QuickcheckDialogComponent } from './dialogs/quickcheck-dialog/quickcheck-dialog.component';
import { LandingQuickcheckDialogComponent } from './dialogs/landing-quickcheck-dialog/landing-quickcheck-dialog.component';
import { RegisterDeviceDialogComponent } from './dialogs/register-device-dialog/register-device-dialog.component';
import { UpdateDeviceStateDialogComponent } from './dialogs/update-device-state-dialog/update-device-state-dialog.component';

import { AgmCoreModule } from '@agm/core';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from './terms-of-service/privacy-policy/privacy-policy.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { DeviceComponent } from './device/device.component';
import { ProfileComponent } from './profile/profile.component';
//import { ChatComponent } from './chat/chat.component';
import { QuickcheckResultComponent } from './dialogs/quickcheck-result/quickcheck-result.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { UserRegSuccesComponent } from './user-reg-succes/user-reg-succes.component';
import { EmailNotVerifiedComponent } from './email-not-verified/email-not-verified.component';
import { HomedashComponent } from './homedash/homedash.component';
import { CarouselComponent } from './carousel/carousel.component';
import { DeviceInfoDialogComponent } from './device-info-dialog/device-info-dialog.component';
import { NotificationInfoDialogComponent } from './notification-info-dialog/notification-info-dialog.component';
import { DashqcheckresultComponent } from './dashqcheckresult/dashqcheckresult.component';
import { StolenideviceComponent } from './stolenidevice/stolenidevice.component';
import { ComsDialogComponent } from './coms-dialog/coms-dialog.component';
import { QcheckIfForSaleResultComponent } from './dashqcheckresult/qcheck-if-for-sale-result/qcheck-if-for-sale-result.component';
import { Chat2Component } from './chat2/chat2.component';
import { ChatInviteDialogComponent } from './chat2/chat-invite-dialog/chat-invite-dialog.component';
import { GmapsComponent } from './stolenidevice/gmaps/gmaps.component';
import { ProvideEmailComponent } from './profile/provide-email/provide-email.component';
import { ProvideSaidComponent } from './profile/provide-said/provide-said.component';
import { NotificationIfInviteComponent } from './notification-info-dialog/notification-if-invite/notification-if-invite.component';
import { ThreedeeModelsComponent } from './threedee-models/threedee-models.component';
import { CameraComponent } from './camera/camera.component';
import { WebcamModule } from 'ngx-webcam';
import { CamPopupComponent } from './cam-popup/cam-popup.component';
import { AccordianComponent } from './accordian/accordian.component';
import { OurpartnersComponent } from './ourpartners/ourpartners.component';
/*import { BuildDetailsHhtpService } from './services/build-details-hhtp.service';

export function fetchBuildDetails(buildDetailsService: BuildDetailsHhtpService) {
  return () => buildDetailsService.fetchBuildDetails();
}*/

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AdsenseModule } from 'ng2-adsense';
import { SignInAltComponent } from './sign-in-alt/sign-in-alt.component';
import { LoginGuardGuard } from './login-guard.guard';












const firebaseConfig = {
  apiKey: "AIzaSyDyoqsIPcqpYk2a4v9cYewtBsNN1AAUsr8",
  authDomain: "ownedby-93415.firebaseapp.com",
  databaseURL: "https://ownedby-93415.firebaseio.com",
  projectId: "ownedby-93415",
  storageBucket: "ownedby-93415.appspot.com",
  messagingSenderId: "362304909303",
  appId: "1:362304909303:web:e5745a3781c4b6a73088f2",
  measurementId: "G-7CEKP569RX"
};

@NgModule({
  declarations: [
    AppComponent,
    SignInPageComponent,
    DashboardComponent,
    SignUpDialogComponent,
    ResetPasswordDialogComponent,
    QuickcheckDialogComponent,
    LandingQuickcheckDialogComponent,
    RegisterDeviceDialogComponent,
    UpdateDeviceStateDialogComponent,

    LandingPageComponent,
    TermsOfServiceComponent,
    PrivacyPolicyComponent,
    DeviceComponent,
    ProfileComponent,
    //ChatComponent,
    QuickcheckResultComponent,
    UserRegSuccesComponent,
    EmailNotVerifiedComponent,

    HomedashComponent,
    CarouselComponent,
    DeviceInfoDialogComponent,
    NotificationInfoDialogComponent,
    DashqcheckresultComponent,
    StolenideviceComponent,
    ComsDialogComponent,
    QcheckIfForSaleResultComponent,
    Chat2Component,
    ChatInviteDialogComponent,
    GmapsComponent,
    ProvideEmailComponent,
    ProvideSaidComponent,
    NotificationIfInviteComponent,
    ThreedeeModelsComponent,
    CameraComponent,
    CamPopupComponent,
    AccordianComponent,
    OurpartnersComponent,
    SignInAltComponent,
    


  ],
  imports: [
    
    AdsenseModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    GoogleMapsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAvcDy5ZYc2ujCS6TTtI3RYX5QmuoV8Ffw',
      language: 'en',
      libraries: ['geometry', 'places']
    }),


    A11yModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    ScrollingModule,
    NgxSpinnerModule,
    WebcamModule ,
    AngularFireStorageModule,
    FontAwesomeModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [/*{ provide: APP_INITIALIZER, useFactory: fetchBuildDetails, deps: [BuildDetailsHhtpService], multi: true },*/LoginGuardGuard],
  bootstrap: [AppComponent],
  entryComponents: [SignUpDialogComponent,
    ResetPasswordDialogComponent,
    QuickcheckDialogComponent,
    LandingQuickcheckDialogComponent,
    RegisterDeviceDialogComponent,
    UpdateDeviceStateDialogComponent,
    UserRegSuccesComponent,
    EmailNotVerifiedComponent,
    ChatInviteDialogComponent,
    DeviceInfoDialogComponent,
    NotificationInfoDialogComponent,
    DashqcheckresultComponent,
    StolenideviceComponent,

    ChatInviteDialogComponent,

    CamPopupComponent,


  ]
})
export class AppModule { }
