import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css'],
})
export class CarouselComponent implements OnInit {


  activePanel: number = 2;

  element1: any | undefined;
  element2: any | undefined;
  element3: any | undefined;
  dot1: any | undefined;
  dot2: any | undefined;
  dot3: any | undefined;

  constructor() {}

  ngOnInit(): void {
    this.element1 = document.getElementById('mainContent1');
    this.element2 = document.getElementById('mainContent2');
    this.element3 = document.getElementById('mainContent3');
    this.dot1 = document.getElementById('paginator1');
    this.dot2 = document.getElementById('paginator2');
    this.dot3 = document.getElementById('paginator3');
    

    this.activePanel = 2;
    
    console.log(this.activePanel);


    this.hitLeft()
  }

  hitLeft() {
    if (this.activePanel === 1) {
      this.activePanel = 3;
      this.element1.style.opacity = '0';
      this.element1.style.transform = 'translateX(-3cm)';

      this.element2.style.opacity = '0';
      this.element2.style.transform = 'translateX(3cm)';
      this.element3.style.opacity = '1.0';
      this.element3.style.transform = 'translateX(0cm)';
      this.dot1.style.background = 'rgb(218, 218, 218)';
      this.dot2.style.background = 'rgb(218, 218, 218)';
      this.dot3.style.background = '#000000';
      console.log(this.activePanel);
    } else if (this.activePanel === 2) {
      this.activePanel = 1;
      this.element1.style.opacity = '1.0';
      this.element1.style.transform = 'translateX(0cm)';
      this.element2.style.opacity = '0';
      this.element2.style.transform = 'translateX(-3cm)';
      this.element3.style.opacity = '0';
      this.element3.style.transform = 'translateX(3cm)';
      this.dot1.style.background = '#000000';
      this.dot2.style.background = 'rgb(218, 218, 218)';
      this.dot3.style.background = 'rgb(218, 218, 218)';
      console.log(this.activePanel);
    } else if (this.activePanel === 3) {
      this.activePanel = 2;
      this.element1.style.opacity = '0';
      this.element1.style.transform = 'translateX(3cm)';
      this.element2.style.opacity = '1.0';
      this.element2.style.transform = 'translateX(0cm)';
      this.element3.style.opacity = '0';
      this.element3.style.transform = 'translateX(-3cm)';
      this.dot1.style.background = 'rgb(218, 218, 218)';
      this.dot2.style.background = '#000000';
      this.dot3.style.background = 'rgb(218, 218, 218)';
      console.log(this.activePanel);
    }
  }
  hitRight() {
    if (this.activePanel === 1) {
      this.activePanel = 2;
      this.element1.style.opacity = '0';
      this.element1.style.transform = 'translateX(3cm)';
      this.element2.style.opacity = '1.0';
      this.element2.style.transform = 'translateX(0cm)';
      this.element3.style.opacity = '0';
      this.element3.style.transform = 'translateX(-3cm)';
      this.dot1.style.background = 'rgb(218, 218, 218)';
      this.dot2.style.background = '#000000';
      this.dot3.style.background = 'rgb(218, 218, 218)';
      console.log(this.activePanel);
    } else if (this.activePanel === 2) {
      this.activePanel = 3;
      this.element1.style.opacity = '0';
      this.element1.style.transform = 'translateX(-3cm)';
      this.element2.style.opacity = '0';
      this.element2.style.transform = 'translateX(3cm)';
      this.element3.style.opacity = '1.0';
      this.element3.style.transform = 'translateX(0cm)';
      this.dot1.style.background = 'rgb(218, 218, 218)';
      this.dot2.style.background = 'rgb(218, 218, 218)';
      this.dot3.style.background = '#000000';
      console.log(this.activePanel);
    } else if (this.activePanel === 3) {
      this.activePanel = 1;
      this.element1.style.opacity = '1.0';
      this.element1.style.transform = 'translateX(0cm)';
      this.element2.style.opacity = '0';
      this.element2.style.transform = 'translateX(-3cm)';
      this.element3.style.opacity = '0';
      this.element3.style.transform = 'translateX(3cm)';
      this.dot1.style.background = '#000000';
      this.dot2.style.background = 'rgb(218, 218, 218)';
      this.dot3.style.background = 'rgb(218, 218, 218)';
      console.log(this.activePanel);
    }
  }
}
