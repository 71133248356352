import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RegisterDeviceDialogComponent } from '../dialogs/register-device-dialog/register-device-dialog.component';
import { Device } from '../interfaces/Device';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user.service';
import { faFilm } from '@fortawesome/free-solid-svg-icons';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: 'app-homedash',
  templateUrl: './homedash.component.html',
  styleUrls: ['./homedash.component.css'],
})
export class HomedashComponent implements OnInit {
  tiles: Tile[] = [
    { text: 'One', cols: 1, rows: 1, color: 'lightblue' },
    { text: 'Two', cols: 1, rows: 1, color: 'lightgreen' },
    { text: 'Three', cols: 1, rows: 1, color: 'lightpink' },
    { text: 'Four', cols: 1, rows: 1, color: '#DDBDF1' },
    { text: 'Four', cols: 2, rows: 1, color: '#DDBDF1' },
  ];

  public devicesCollection: AngularFirestoreCollection<Device> | undefined;
  public devices: Observable<Device[]> | undefined;

  currentUserArray: any[] = [];

  name: any;
  secondName: any;
  emailAddress: any;

  filmIcon = faFilm;

  constructor(
    private firestore: AngularFirestore,
    private authService: AuthenticationService,
    public dialog: MatDialog,
    private afAuth: AngularFireAuth,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.authService.buildUserDetails(this.currentUserArray);
  }

  openRegisterNewDevice() {
    this.dialog.open(RegisterDeviceDialogComponent);
  }
}
