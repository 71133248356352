import { EventEmitter, Injectable, NgZone, Output } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/auth';
import { Router, RouterStateSnapshot } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard.component';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { UserService } from './user.service';
import { MatDialog } from '@angular/material/dialog';
import { UserRegSuccesComponent } from '../user-reg-succes/user-reg-succes.component';
import { EmailNotVerifiedComponent } from '../email-not-verified/email-not-verified.component';

import { NgxSpinnerService } from 'ngx-spinner';
import { stringify } from '@angular/compiler/src/util';
import { delay, first, tap } from 'rxjs/operators';
import { ProfileScreen } from '../interfaces/profile-screen';
import { ComsServiceService } from './coms-service.service';
import { LoginGuardGuard } from '../login-guard.guard'

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {


  isLoggedIn = false;

  // store the URL so we can redirect after logging in
  redirectUrl: string = 'signIn';




  quickcheckResponse: any;

  @Output() public authState: EventEmitter<any> = new EventEmitter();

  @Output() public authError: EventEmitter<any> = new EventEmitter();


  public continueFlag: boolean = false;


  constructor(
    private afAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private router: Router,
    public ngZone: NgZone,
    private fns: AngularFireFunctions,
    private userService: UserService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    public comService: ComsServiceService,

  ) { }

  user: any;
  curUser: any;
  userDoc: any;
  fireId: any;



  login(): Observable<boolean> {
    return of(true).pipe(
      delay(1000),
      tap(val => this.isLoggedIn = true)
    );
  }

  getNotifications(notificationsArray: any[]) {
    this.afAuth.onAuthStateChanged((user) => {
      if (user) {
        this.firestore
          .collection('chats', (ref) => ref.where('userId', '==', user.uid))
          .get()
          .subscribe((ss) => {
            ss.docs.forEach((doc) => {
              notificationsArray.push(doc.data());
            });
          });
      } else {
        console.log('skirri');
        // User is signed out
        // ...
      }
    });
  }

  onPhoneSubmit() { }

  imeiURL(email: string, password: string, autoiemi: any) {
    this.continueFlag = true;
  }

  signUp(
    firstNameSubmitted: string,
    lastNameSubmitted: string,
    emailSubmitted: string,
    passwordSubmitted: string,
    confirmPasswordSubmitted: string
  ) {
    const email = emailSubmitted;
    const password = passwordSubmitted;
    console.log('about to create a new user', email, password);
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user;

        this.firestore.collection('users').doc(userCredential.user?.uid).set({
          firstName: firstNameSubmitted,
          lastName: lastNameSubmitted,
        });

        this.dialog.closeAll();

        this.dialog.open(UserRegSuccesComponent);

        console.log(
          'written to firestore with docId',
          userCredential.user?.uid
        );

        this.authStateListener();
        this.sendVerMail();
        // ...
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(error.code, errorMessage);
        /*if (error.code === "auth/email-already-in-use") {
          this.dialog.open(EmailFormatWrongComponent);
        }*/
        // ..
      });
  }

  signIn(email: string, password: string) {
    console.log('about to sign in existing user', email, password);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed in
        //console.log('Emitting event : ' + userCredential);
        //this.authState.emit(JSON.stringify(userCredential));
        if (userCredential.user?.emailVerified === true) {
          this.userService.setUserLoggedIn(userCredential.user.uid, email);
          this.router.navigate(['dashboard']);


          this.spinner.hide();
        } else {
          this.sendVerMail();
          this.spinner.hide();
          this.dialog.open(EmailNotVerifiedComponent);
        }
        // ...
      })
      .catch((error) => {
        this.spinner.hide();
        window.alert(error.message);
        var errorCode = error.code;
        var errorMessage = error.message;
      });
  }



  buildUserDetails(currentUserArray: any[]) {
    var first: string;

    this.afAuth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        this.firestore
          .collection('users')
          .doc(user.uid)
          .get()
          .subscribe((doc) => {
            currentUserArray.push(doc.data());
          });

        // ...
      } else {
        console.log('skirri');
        // User is signed out
        // ...
      }
    });
  }

  checkState() {
    this.afAuth.authState.subscribe((res) => {
      if (res && res.uid) {
        console.log('user is logged in', res);
      } else {
        console.log('user not logged in');
        this.router.navigate(['signIn']);
      }
    });
  }

  populateProfileScreen(UserArray: any) {
    this.afAuth.authState.subscribe((res) => {
      console.log(res, 'lets see result');
      var userUID = res?.uid;

      console.log(userUID, 'wawa');

      var docRef = this.firestore.collection('users').doc(userUID).get();

      docRef.subscribe((doc) => {
        console.log('ooooooooooooooo', doc.data());

        UserArray.push(doc.data());
      });
    });
  }

  private quickcheckFunction = this.fns.httpsCallable('quickcheck');

  quickcheck(serial: string) {
    return this.quickcheckFunction({
      input: serial,
      source: 'Web',
      user: this.userService.getUserLoggedIn(),
    });

  }

  async registerDevice(brand: string, nickname: string, imei: string) {

    console.log(brand, nickname, imei,  'inside service');

    const docRef = this.firestore.collection(('products'), ref =>
      ref.where('imei', '==', imei)
    );
    docRef.get().toPromise().then((doc) => {
      if (doc.empty === true) {
        console.log("Document data:", doc);
        this.firestore
          .collection('products')
          .add({
            nickname: nickname,
            imei: imei,
            userId: this.userService.getUserLoggedIn(),
            brand: brand,
            state: 'New',
            verified: false,
            dateCreated: new Date(),
          })
          .then(function (docRef) {
            console.log('Document written with ID: ', docRef.id);
            window.alert(
              nickname +
              ' has been registered. The device will be sent for verification and once verified, will appear in your Devices page'
            );
          });
      } else {

        this.comService.imeiAlreadyInUse();
      }
    }).catch((error: any) => {
      console.log("Error getting document:", error);
    });


  }

  authStateListener() {
    // [START auth_state_listener]
    this.afAuth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        var uid = user.uid;

        // ..
      } else {
        // User is signed out
        // ...
      }
    });
    // [END auth_state_listener]
  }


  authStateListenerAuto() {
    // [START auth_state_listener]
    this.afAuth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User

        return true;

        // ..
      } else {
        return  false;
        // User is signed out
        // ...
      }
    });
    // [END auth_state_listener]
  }

  getCurrentUser() {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        console.log('user signed in:', user.uid);
        return user;
      } else {
        console.log('user signed in:', user);
        // No user is signed in.
        return user;
      }
    });
  }

  getCurrentUserAuto() {
    var user = firebase.auth().currentUser;


    if (user != null) {
      return true

    }

    else return false
  }

  getCurrentUser2() {
    var user = firebase.auth().currentUser;

    if (user) {
      console.log('user signed in:', user);
      // User is signed in.
    } else {
      // No user is signed in.
    }
  }

  getProfile() {
    var user = firebase.auth().currentUser;
    var name, email, photoUrl, uid, emailVerified;

    if (user != null) {
      name = user.displayName;
      email = user.email;
      photoUrl = user.photoURL;
      emailVerified = user.emailVerified;
      uid = user.uid;
    }
  }

  /*getProviderInfo() {
    var user = firebase.auth().currentUser;
  
    if (user != null) {
      user.providerData.forEach(function (profile) {
        console.log("Sign-in provider: " + profile.providerId);
        console.log("  Provider-specific UID: " + profile.uid);
        console.log("  Name: " + profile.displayName);
        console.log("  Email: " + profile.email);
        console.log("  Photo URL: " + profile.photoURL);
      });
    }
  }*/

  updateProfile() {
    var user = firebase.auth().currentUser;

    if (user != null) {
      user
        .updateProfile({
          displayName: 'Jane Q. User',
          photoURL: 'https://example.com/jane-q-user/profile.jpg',
        })
        .then(function () {
          // Update successful.
        })
        .catch(function (error) {
          // An error happened.
        });
    }
  }

  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail, {
        url: 'https://ownedby-93415.firebaseapp.com/signIn/',
      })
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
        //this.dialog.closeAll();
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  sendVerMail() {
    var user = firebase.auth().currentUser;

    if (user != null) {
      user
        .sendEmailVerification({
          url: 'https://ownedby-93415.firebaseapp.com/signIn/',
        })
        .then(function () {
          // Email sent.
        })
        .catch(function (error) {
          // An error happened.
        });
    }
  }

  resetPasswordMail() {
    var auth = firebase.auth();
    var emailAddress = 'user@example.com';

    auth
      .sendPasswordResetEmail(emailAddress)
      .then(function () {
        // Email sent.
      })
      .catch(function (error) {
        // An error happened.
      });
  }

  setKey(key: string) {
    var user = firebase.auth().currentUser;

    if (user) {
      console.log('user signed in:', user.uid);
      user.uid.toString();
      key = user.uid;
      // User is signed in.
    } else {
      // No user is signed in.
    }

    this.afAuth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        var uid = user.uid;
        key = uid;

        // ..
      } else {
        // User is signed out
        // ...
      }
    });
  }

  deleteUser() {
    var user = firebase.auth().currentUser;

    if (user != null) {
      user
        .delete()
        .then(function () {
          // User deleted.
        })
        .catch(function (error) {
          // An error happened.
        });
    }
  }

  /*reAuthUser() {
    var user = firebase.auth().currentUser;
    var credential;
  
    if (user != null) {
      var user = firebase.auth().currentUser;
      var credential;
  
      // Prompt the user to re-provide their sign-in credentials
  
      user.reauthenticateWithCredential(credential).then(function () {
        // User re-authenticated.
      }).catch(function (error) {
        // An error happened.
      });
    }
  }*/

  signOut() {
    this.isLoggedIn = false;
    firebase
      .auth()
      .signOut()
      .then(() => {
        console.log('singed out');
        this.router.navigate(['signIn']);
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  }
}
