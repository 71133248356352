<body>
    <h2>Anonymous QuickCheck</h2>
    <hr>
    <form [formGroup]="checkForm" (ngSubmit)="onCheck()">
        <div class="form-group">
            <input type="text" class="form-control" formControlName="imei" placeholder="Enter Serial/IMEI Number">
            <button mat-flat-button color="primary" class="radius" type="submit">QuickCheck</button>
        </div>
    </form>
    <hr>
    <p>QuickCheck results</p>
    <p>Status:{{quickcheckResponse.status}}</p>
    <p>Brand:{{quickcheckResponse.name}}</p>
    <p>State:{{quickcheckResponse.deviceState}}</p>
    <!--<hr>
    <div>
        <form [formGroup]="emailDeviceReportForm" (ngSubmit)="onSubmitEmailForReport()">
            <label for="email"> Email
                <input type="email" formControlName="reportEmail" placeholder="Enter an Email Address">
            </label>
            <button mat-flat-button color="primary" class="radius" type="submit">Send Report</button>
        </form>
    </div>
    <hr>
    <div>
        <button mat-flat-button color="primary" class="radius" (click)="closeDialog()">Close</button>
    </div>-->
</body>