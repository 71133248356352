import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  AngularFireStorage,
  AngularFireStorageReference,
  AngularFireUploadTask,
} from '@angular/fire/storage';

import { WebcamImage } from 'ngx-webcam';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UserService } from '../services/user.service';
/*import { FileUpload } from './fileupload';*/

@Component({
  selector: 'app-cam-popup',
  templateUrl: './cam-popup.component.html',
  styleUrls: ['./cam-popup.component.css'],
})
export class CamPopupComponent implements OnInit {
  webcamImage!: WebcamImage;

  filePath!: String;

  notificationRef: any = this.userService.getUserLoggedIn();

  key!: string;
  name!: string;
  url!: string;
  file!: File;

  private basePath = '/uploads';

  constructor(
    private afStorage: AngularFireStorage,
    private userService: UserService,
    private db: AngularFirestore,

  ) {

  }

  /*pushFileToStorage(fileUpload: FileUpload): Observable {
  const filePath = `${this.basePath}/${fileUpload.file.name}`;
  const storageRef = this.afStorage.ref(filePath);
  const uploadTask = this.afStorage.upload(filePath, fileUpload.file);

  uploadTask.snapshotChanges().pipe(
    finalize(() => {
      storageRef.getDownloadURL().subscribe(downloadURL => {
        console.log('File available at', downloadURL);
        fileUpload.url = downloadURL;
        fileUpload.name = fileUpload.file.name;
        this.saveFileData(fileUpload);
      });
    })
  ).subscribe();

  return uploadTask.percentageChanges();
}

private saveFileData(fileUpload: FileUpload) {
  this.afStorage.list(this.basePath).push(fileUpload);
}

*/

  ngOnInit(): void {}

  upload(event: any) {
    this.filePath = event.target.files[0];
    console.log(event);
  }

  uploadImage() {
    console.log(this.filePath);
    this.afStorage.upload(
      '/profiles/images' + this.notificationRef,
      this.filePath
    );
  }

  handleImage(webcamImage: WebcamImage) {
    this.webcamImage = webcamImage;
    console.log(this.webcamImage);
    //this.filePath = this.webcamImage.imageAsBase64
  }
}
