import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { SignUpDialogComponent } from "../dialogs/sign-up-dialog/sign-up-dialog.component";
import { ResetPasswordDialogComponent } from '../dialogs/reset-password-dialog/reset-password-dialog.component';
import { UserService } from '../services/user.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ComsServiceService } from '../services/coms-service.service';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { MatMenuTrigger } from '@angular/material/menu';
import { OverlaysService } from '../services/overlays.service';
import * as firebase from 'firebase';
import { EmailNotVerifiedComponent } from '../email-not-verified/email-not-verified.component';
import { EventEmitter, Injectable, NgZone, Output } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/auth';

import 'firebase/firestore';

import { DashboardComponent } from '../dashboard/dashboard.component';

import { BehaviorSubject,  } from 'rxjs';


import { UserRegSuccesComponent } from '../user-reg-succes/user-reg-succes.component';
import { AdsenseModule } from 'ng2-adsense';
import { delay, tap } from 'rxjs/operators';
import { LoginGuardGuard } from '../login-guard.guard';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';






@Component({
  selector: 'app-sign-in-page',
  templateUrl: './sign-in-page.component.html',
  styleUrls: ['./sign-in-page.component.css']
})
export class SignInPageComponent implements OnInit {

  autoiemi!: any;
  email: any;
  password: any;







  loginForm = this.fb.group({
    email: new FormControl(''),
    password: new FormControl(''),
  });


  /*public fadeAway : boolean = false;

  public overlayShowing: boolean = true;*/

  isLoggedIn = false;

  // store the URL so we can redirect after logging in
  // store the URL so we can redirect after logging in
  redirectUrl!: string;

  message!: string;



  



  





  constructor(
    //private afAuth: AngularFireAuth,
    public dialog: MatDialog,
    private authService: AuthenticationService,
    private fb: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    public overlayServ: OverlaysService,
    public firebase: AngularFireAuth,


    private userService: UserService,
    public guard: LoginGuardGuard,
    private route: ActivatedRoute,
    //private readonly routers: Router,


    
    //private signInForm: FormGroup,
  ) {


    this.setMessage();
    //this.routers.events
    //.subscribe(console.log)

    


    

    

  }
  setMessage() {
    this.message = 'Logged ' + (this.isLoggedIn ? 'in' : 'out');
  }




  ngOnInit() {

    this.authService.imeiURL(this.email, this.password, this.autoiemi);



    

    /*window.addEventListener('load', (event) => {
      console.log('page is fully loaded');

      this.overlayShowing = false;

      this.revealPage();
    });*/



  }

  imeiURL(email: string, password: string, autoiemi: any) {
    this.authService.continueFlag = true;
  }

  login(): Observable<boolean> {
    console.log();
    return of(true).pipe(
      delay(1000),
      tap(val => this.isLoggedIn = true)
    );
  }

  logout(): void {
    this.isLoggedIn = false;
  }

  revealPage() {
    
  }


  signIn(email: string, password: string) {
    console.log('about to sign in existing user', email, password);
    this.firebase
      
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed in

        if (userCredential.user?.emailVerified === true) {
          this.userService.setUserLoggedIn(userCredential.user.uid, email);
          this.spinner.hide();
          this.authService.isLoggedIn = true;
        this.router.navigate(['dashboard']);       } else {
          this.authService.sendVerMail();
          this.spinner.hide();
          this.dialog.open(EmailNotVerifiedComponent);
        }
        // ...
      })
      .catch((error) => {
        this.spinner.hide();
        window.alert(error.message);
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log('jouma')
      });
  }


  

  

  




  onSignIn() {
    this.spinner.show();
    var email: string = this.loginForm.value.email;
    var password: string = this.loginForm.value.password;
    this.signIn(email, password);
  
  }

  

  

  seeUser() {
    this.authService.getCurrentUser()
  }

  passwordReset() {
    
  }

  openRegisterDialog() {
    this.dialog.open(SignUpDialogComponent);
  }

  openResetPassword() {
    this.dialog.open(ResetPasswordDialogComponent);
  }
  googleLogin() {
    window.alert("Google in process of approving domain first before Sign-up/Sign-in functional");
  }



}
