import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { QuickCheckResult } from 'src/app/interfaces/QuickCheckResult';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-provide-said',
  templateUrl: './provide-said.component.html',
  styleUrls: ['./provide-said.component.css']
})
export class ProvideSaidComponent implements OnInit {

  SAidForm = this.fb.group({
    idSubmitted: new FormControl('', [Validators.required]),
  })


  constructor(
    private fb: FormBuilder,
    private firestore: AngularFirestore,
    private userService: UserService,
    public router: Router,


    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  onSubmit() {
    var notificationRef: any = this.userService.getUserLoggedIn();

    this.firestore.collection("users").doc(notificationRef).update({
      saIdNumber: this.SAidForm.value.idSubmitted,

    })
      .then((docRef: any) => {
        console.log("Id creatted");
        this.dialog.closeAll();
        
      })
  }

  close() {
    this.dialog.closeAll();
  }

}
