<div class="mastertwo">
  <div
    class="overlay"
    [class.active]="overlayVisible"
    (click)="toggleNotificationsBox()"
  ></div>
  <div
    class="overlay2"
    [class.active]="overlayVisible2"
    (click)="togglesidenav()"
  ></div>
  <div class="upperBar">
    <div>
      <button (click)="togglesidenav()" class="notifyButton2">
        <mat-icon class="burger">menu</mat-icon>
      </button>
    </div>

    <div>
      <!--<div><p>unread:</p><p>{{newNotification}}</p></div>-->
      <button (click)="toggleNotificationsBox()" class="notifyButton">
        <mat-icon class="bell"
          ><div
            *ngIf="badgeTally > 0"
            class="badgeStuff"
            matBadge="{{ badgeTally }}"
            matBadgeColor="warn"
            matBadgePosition="below before"
            MatBadgeSize="medium"
          ></div>
          notifications</mat-icon
        >
      </button>
    </div>
  </div>

  <div class="boxToRight" [class.active]="notificationsBoxVisible">
    <div class="notificationsBox">
      <div *ngIf="newNotification < 0"><p>no Messages yet</p></div>

      <div *ngFor="let notification of notification | async; index as i">
        <div *ngIf="notification.type === 'QuickCheck'">
          <div
            (click)="viewNotification(notification)"
            class="notificationFlex"
          >
            <mat-icon>search</mat-icon>
          </div>
          <div>
            <div>
              <p class="notificationTimeText">
                {{ notification.date.toDate() | date: "dd MMM yyyy HH:mm" }}
              </p>
            </div>
            <div>
              <p class="notificationsMessageText">
                {{ notification.message }}
              </p>
            </div>
          </div>
        </div>

        <div *ngIf="notification.type === 'ChatInvite'">
          <div
            (click)="viewNotification(notification)"
            class="notificationFlex"
          >
            <mat-icon>home</mat-icon>
          </div>
          <div>
            <div>
              <p class="notificationTimeText">
                {{ notification.date.toDate() | date: "dd MMM yyyy HH:mm" }}
              </p>
            </div>
            <div>
              <p class="notificationsMessageText">
                {{ notification.message }}
              </p>
            </div>
          </div>
        </div>

        <div *ngIf="notification.type === 'StatusStolen'">
          <div
            (click)="viewNotification(notification)"
            class="notificationFlex"
          >
            <mat-icon>home</mat-icon>
          </div>
          <div>
            <div>
              <p class="notificationTimeText">
                {{ notification.date.toDate() | date: "dd MMM yyyy HH:mm" }}
              </p>
            </div>
            <div>
              <p class="notificationsMessageText">
                {{ notification.message }}
              </p>
            </div>
          </div>
        </div>

        <div *ngIf="notification.type === 'StatusVerified'">
          <div
            (click)="viewNotification(notification)"
            class="notificationFlex"
          >
            <mat-icon>home</mat-icon>
          </div>
          <div>
            <div>
              <p class="notificationTimeText">
                {{ notification.date.toDate() | date: "dd MMM yyyy HH:mm" }}
              </p>
            </div>
            <div>
              <p class="notificationsMessageText">
                {{ notification.message }}
              </p>
            </div>
          </div>
        </div>

        <div *ngIf="notification.type === 'StatusLost'">
          <div
            (click)="viewNotification(notification)"
            class="notificationFlex"
          >
            <mat-icon>home</mat-icon>
          </div>
          <div>
            <div>
              <p class="notificationTimeText">
                {{ notification.date.toDate() | date: "dd MMM yyyy HH:mm" }}
              </p>
            </div>
            <div>
              <p class="notificationsMessageText">
                {{ notification.message }}
              </p>
            </div>
          </div>
        </div>

        <div *ngIf="notification.type === 'StatusForSale'">
          <div
            (click)="viewNotification(notification)"
            class="notificationFlex"
          >
            <mat-icon>home</mat-icon>
          </div>
          <div>
            <div>
              <p class="notificationTimeText">
                {{ notification.date.toDate() | date: "dd MMM yyyy HH:mm" }}
              </p>
            </div>
            <div>
              <p class="notificationsMessageText">
                {{ notification.message }}
              </p>
            </div>
          </div>
        </div>

        <div *ngIf="notification.type === 'StatusActive'">
          <div
            (click)="viewNotification(notification)"
            class="notificationFlex"
          >
            <mat-icon>home</mat-icon>
          </div>
          <div>
            <div>
              <p class="notificationTimeText">
                {{ notification.date.toDate() | date: "dd MMM yyyy HH:mm" }}
              </p>
            </div>
            <div>
              <p class="notificationsMessageText">
                {{ notification.message }}
              </p>
            </div>
          </div>
        </div>

        <div class="divider"></div>
      </div>
    </div>
  </div>

  <div class="boxToLeft" [class.active]="sideNavBoxVisible">
    <div class="sidenavBox">
      <div class="sidev3">
        <div class="fourthCard">
          <div class="sidenavFix" style="text-align: center">
            <div>
              <img
                #myDiv
                id="devpage"
                class="toolBarLogo"
                src="/assets/OwnedbyBlack.png"
              />
            </div>
          </div>

          <div class="thirdCardonSide">
            <div class="sidenavFix bitLeftMarginForNavar">
              <div class="spaceLine"></div>
              <a
                [routerLink]="[{ outlets: { sidenav: ['home'] } }]"
                (click)="togglesidenav()"
                class="newSideIcons"
              >
              <mat-icon class="iconSidebarSizing">dashboard</mat-icon>
                <p class="p1">My Dashboard</p>
              </a>
              <a
                [routerLink]="[{ outlets: { sidenav: ['devices'] } }]"
                (click)="togglesidenav()"
                class="newSideIcons"
              >
              <mat-icon class="iconSidebarSizing">tablet_android</mat-icon>
                <p class="p1">Devices</p>
              </a>

              <a
                [routerLink]="[{ outlets: { sidenav: ['chat'] } }]"
                (click)="togglesidenav()"
                class="newSideIcons"
              >
              <mat-icon class="iconSidebarSizing">chat_bubble_outline</mat-icon>
                <p class="p1">Chat-gateway</p>
              </a>
              
              <a
                [routerLink]="[{ outlets: { sidenav: ['profile'] } }]"
                (click)="togglesidenav()"
                class="newSideIcons"
              >
              <mat-icon class="iconSidebarSizing">account_circle</mat-icon>
                <p class="p1">Profile</p>
              </a>
            </div>
            

            <div class="seconCardonSide">
              <div class="sidenavFix" style="text-align: center">
                <button
                  class="greyButton qcheckButton"
                  mat-flat-button
                  (click)="openUserQuickCheck()"
                >
                  QuickCheck
                </button>
              </div>

              <div class="firstCardonSide" style="text-align: center">
                <div class="sidenavFix">
                  <a
                    [routerLink]="[{ outlets: { sidenav: ['terms'] } }]"
                    (click)="togglesidenav()"
                  >
                    <p class="p2">Terms of Service</p>
                  </a>
                  <a
                    [routerLink]="[{ outlets: { sidenav: ['privacy'] } }]"
                    (click)="togglesidenav()"
                  >
                    <p class="p2">Privacy Policy</p>
                  </a>
                  <button
                    (click)="signOut()"
                    class="radius2"
                    mat-flat-button
                    style="text-align: center"
                  >
                    Sign Out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="overlay"
    [class.active]="overlayVisible"
    (click)="toggleNotificationsBox()"
  ></div>

  <div class="sidev2">
    <div
      class="overlay"
      [class.active]="overlayVisible"
      (click)="toggleNotificationsBox()"
    ></div>

    <div class="fourthCard">
      <div class="sidenavFix" style="text-align: center">
        <div>
          <img
            #myDiv
            id="devpage"
            class="toolBarLogo"
            src="/assets/OwnedbyBlack.png"
          />
        </div>
      </div>
      <div class="sideAccent" ></div>

      <div class="thirdCardonSide">
        <div class="sidenavFix bitLeftMarginForNavar">
          <div class="spaceLine"></div>

          <div id="sideone" class="hoverSide hoverSidetwo">
            <a
              [routerLink]="[{ outlets: { sidenav: ['home'] } }]"
              class="newSideIcons"
              (click)="homeActive()"
            >
              <mat-icon class="iconSidebarSizing">dashboard</mat-icon>
              <p class="p1">My Dashboard</p>
            </a>
          </div>
          <div id="sidetwo" class="hoverSide hoverSidetwo">
            <a
              [routerLink]="[{ outlets: { sidenav: ['devices'] } }]"
              class="newSideIcons"
              (click)="deviceActive()"
            >
              <mat-icon class="iconSidebarSizing">tablet_android</mat-icon>
              <p class="p1">Devices</p>
            </a>
          </div>
          <div id="sidethree" class="hoverSide hoverSidetwo">
            <a
              [routerLink]="[{ outlets: { sidenav: ['chat'] } }]"
              class="newSideIcons"
              (click)="chatActive()"
            >
              <mat-icon class="iconSidebarSizing">chat_bubble_outline</mat-icon>
              <p class="p1">Chat-gateway</p>
            </a>
            
          </div>
          <div id="sidefour" class="hoverSide hoverSidetwo">
            <a
              [routerLink]="[{ outlets: { sidenav: ['profile'] } }]"
              class="newSideIcons"
              (click)="profileActive()"
            >
              <mat-icon class="iconSidebarSizing">account_circle</mat-icon>

              <p class="p1">Profile</p>
            </a>
          </div>
        </div>
        <a mat-list-item [routerLink]="[{ outlets: { 'sidenav': ['chat2'] } }]"><p>chat2</p></a>

        <div class="sideAccent" ></div>

        <div class="seconCardonSide">
          <div class="sidenavFix" style="text-align: center">
            <button
              class="qcheckButton"
              mat-flat-button
              (click)="openUserQuickCheck()"
            >
              QuickCheck
            </button>
          </div>

          <div class="sideAccent" ></div>

          <div class="firstCardonSide" style="text-align: center">
            <div class="sidenavFix">
              <a [routerLink]="[{ outlets: { sidenav: ['terms'] } }]">
                <p class="p2">Terms of Service</p>
              </a>
              <a [routerLink]="[{ outlets: { sidenav: ['privacy'] } }]">
                <p class="p2">Privacy Policy</p>
              </a>
              <button
                (click)="signOut()"
                class="radius2"
                mat-flat-button
                style="text-align: center"
              >
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="outletWrapper">
    <div class="ro">
      <router-outlet name="sidenav"></router-outlet>
    </div>
  </div>
</div>
