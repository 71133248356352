<div class="cardSize">
  <div class="exitButtonRight">
    <div>
      <img class="logo" src="/assets/OwnedbyBlackChatBack.png" />
    </div>
    <div>
      <mat-icon class="exitButton" (click)="close()">close</mat-icon>
    </div>
  </div>
  <div style="text-align: center">
    <div class="ghost"></div>
    <h2 class="fontOrange">Device Results</h2>
    <h3 class="font">Device : {{ quickCheckData.name }}</h3>
    <h3 class="font">Device Status : {{ quickCheckData.deviceState }}</h3>
    <h3 class="font">
      Blacklist Status : {{ quickCheckData.blacklistStatus }}
    </h3>
    <form [formGroup]="emailForm" (ngSubmit)="email(quickCheckData)">
      <mat-form-field
        appearance="legacy"
        class="formWidth"
        style="text-align: center"
      >
        <mat-label>Enter your email address</mat-label>
        <input
          matInput
          type="email"
          formControlName="emailSubmitted"
          id="emailAddress"
          required
        />
        <mat-error *ngIf="hasError('emailSubmitted', 'required')"
          >Email is required</mat-error
        >
        <mat-error *ngIf="hasError('emailSubmitted', 'email')"
          >Must be a valid email address</mat-error
        >
      </mat-form-field>
      <div class="ghost"></div>
      <p>
        <mat-checkbox (click)="toggleClass()" class="boxMargin"></mat-checkbox>I
        accept the <a href="termsService">Terms of Service</a> and
        <a href="PrivacyPolicy">Privacy Policy</a>.
      </p>
      <button
        mat-flat-button
        [class.active]="classApplied"
        class="but"
        type="submit"
      >
        Email me full report
      </button>
      <button
        mat-flat-button
        [class.active]="classApplied2"
        type="button"
        (click)="agree()"
        class="dis"
      >
        Email me full report
      </button>
      <div class="ghost"></div>
    </form>
    <div class="ghost"></div>
    <div class="ghost"></div>
  </div>
</div>
