<ngx-spinner></ngx-spinner>

<div class="size">
    <div class="qcheckBox" style="font-size: 12px">
        <form class="parent" [formGroup]="quickCheckForm" (ngSubmit)="quickcheck()">
            <div class="pp">
                <mat-form-field appearance="legacy" class="formWidth">
                    <mat-label>Enter an IMEI number</mat-label>
                    <input matInput formControlName="imeiSubmitted" id="imeiField" #txtInput>
                    <mat-error *ngIf="hasError('imeiSubmitted', 'required')">IMEI is required</mat-error>
                    <mat-error *ngIf="hasError('imeiSubmitted', 'pattern')">IMEI number must be 15 digits
                    </mat-error>
                </mat-form-field>
            </div>
            <div>
                <input type="image" class="qCheckPic" name="submit" src="/assets/qcheck.PNG" alt="Submit">
            </div>
        </form>
    </div>
</div>
