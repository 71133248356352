import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LandingQuickcheckDialogComponent } from 'src/app/dialogs/landing-quickcheck-dialog/landing-quickcheck-dialog.component';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AngularFireFunctions } from '@angular/fire/functions';
import { QuickcheckResultComponent } from '../dialogs/quickcheck-result/quickcheck-result.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ComsServiceService } from '../services/coms-service.service';
import { OverlaysService } from '../services/overlays.service';
import { from } from 'rxjs';
import { HostListener } from '@angular/core';
import { reduce } from 'rxjs/operators';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css'],
})
export class LandingPageComponent implements OnInit {
  opened: boolean = false;
 
  responsivenav: any

  

  @HostListener('window:scroll', ['$event'])

  @ViewChild('txtInput')
  txtInput!: ElementRef;


  onscroll = function () {
    onWindowScroll();
  };

  quickCheckForm = this.fb.group({
    imeiSubmitted: new FormControl('', [
      Validators.pattern(
        '[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]'
      ),
    ]),
  });

  contactUsForm = this.fb.group({
    name: new FormControl(''),
    email: new FormControl(''),
    message: new FormControl(''),
  });

  public onComp: boolean = true;
  public onComp2: boolean = true;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private fb: FormBuilder,
    private fns: AngularFireFunctions,
    private spinner: NgxSpinnerService,
    public comService: ComsServiceService,
    public overlayServ: OverlaysService
  ) {
    window.addEventListener('load', (event) => {
      console.log('page is fully loaded');

      this.revealPage();
    });
  }

  ngOnInit(): void {
    this.responsivenav = document.getElementById('newnav');
  }

  revealPage() {
    this.onComp = false;
    this.onComp2 = false;
  }

  comserviceTest() {
    this.comService.alertTemplate();
  }

  onWindowScroll() {
    if (window.pageYOffset < 2) {
      this.responsivenav.style.background = "red";
    } else {
      this.responsivenav.style.background = "pink";
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.quickCheckForm.controls[controlName].hasError(errorName);
  };

  scrollToHome() {
    var elmnt: any = document.getElementById('scrollHome');
    elmnt.scrollIntoView({ behavior: 'smooth' });
  }

  scrollToScreens() {
    var elmnt: any = document.getElementById('scrollScreens');
    elmnt.scrollIntoView({ behavior: 'smooth' });
  }

  scrollToFeatures() {
    var elmnt: any = document.getElementById('scrollFeatures');
    elmnt.scrollIntoView({ behavior: 'smooth' });
  }

  scrollToContactUs() {
    var elmnt: any = document.getElementById('scrollContactUs');
    elmnt.scrollIntoView({ behavior: 'smooth' });
  }

  scrollToHomeMobile() {
    var elmnt: any = document.getElementById('scrollHomeM');
    elmnt.scrollIntoView({ behavior: 'smooth' });
  }

  scrollToScreensMobile() {
    var elmnt: any = document.getElementById('scrollScreensM');
    elmnt.scrollIntoView({ behavior: 'smooth' });
  }

  scrollToFeaturesMobile() {
    var elmnt: any = document.getElementById('scrollFeaturesM');
    elmnt.scrollIntoView({ behavior: 'smooth' });
  }

  scrollToContactMobile() {
    var elmnt: any = document.getElementById('scrollContactUsM');
    elmnt.scrollIntoView({ behavior: 'smooth' });
  }

  toSignIn() {
    this.overlayServ.activateOverL()
    this.router.navigate(['signIn']);
  }

  anonymousQuickCheck() {
    this.dialog.open(LandingQuickcheckDialogComponent);
  }

  openRegisterNewDevice() {}

  goToAppstore() {}

  setFocus() {
    this.txtInput.nativeElement.focus();
  }

  onContact() {
    console.log(this.contactUsForm.value);
  }


  onScroll() {
    
  }

  goToPlaystore() {}

  private quickcheckFunction = this.fns.httpsCallable('quickcheck');

  quickcheck() {
    this.spinner.show();
    console.log('Doing quickcheck');
    this.quickcheckFunction({
      input: this.quickCheckForm.value.imeiSubmitted,
      source: 'Web',
      user: '',
    }).subscribe((result) => {
      console.log(result);
      this.spinner.hide();
      this.dialog.open(QuickcheckResultComponent, {
        data: {
          name: result.name,
          claimed: result.claimed,
          deviceState: result.deviceState,
          deviceUser: result.deviceUser,
          searchId: result.searchId,
          status: result.status,
          blacklistStatus: result.blacklistStatus,
        },
      });

      // return this.quickcheckFunction({ input: this.quickCheckForm.value.imeiSubmitted, source: 'Web', user: null });
    });
  }
}
function onWindowScroll() {
  throw new Error('Function not implemented.');
}

