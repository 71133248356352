
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Notifications } from '../../interfaces/notifications';

@Component({
  selector: 'app-notification-if-invite',
  templateUrl: './notification-if-invite.component.html',
  styleUrls: ['./notification-if-invite.component.css']
})
export class NotificationIfInviteComponent implements OnInit {

  public notificationInfoData: Notifications;


  dataYouWantToReturn = true; //set data.



  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Notifications,
    private dialogRef: MatDialogRef<NotificationIfInviteComponent>,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    public router: Router,
  ) {
    this.notificationInfoData = data;
    
    
    
   }

  ngOnInit(): void {
  }




  close() {
    this.dialog.closeAll();
  }

  onGoToChat() {

    



  }

}
