import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-threedee-models',
  templateUrl: './threedee-models.component.html',
  styleUrls: ['./threedee-models.component.css'],
})
export class ThreedeeModelsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    this.changeSide();
  }

  changeSide() {
    var box: any = document.querySelector('.box');
    var radioGroup = document.querySelector('.radio-group');
    var currentClass = '';
  }
  // set initial side
}
