<h1>Privacy Policy for Ownedby Technologies (Pty) Ltd</h1>
<p>At Ownedby, accessible from www.ownedby.co.za / www.ownedbyZA.com, one of our main
    priorities is the privacy of our visitors. This Privacy Policy document contains types of information
    that is collected and recorded by Ownedby and how we use it.
    If you have additional questions or require more information about our Privacy Policy, do not
    hesitate to contact us.
    This Privacy Policy applies only to our online activities and is valid for visitors to our website and
    App (“platforms”), with regards to the information that they shared with and/or collect by
    Ownedby. This Privacy Policy relates to the collection and use of personal information you may
    supply to us through your use of the service channels our platforms and such policy therefore
    governs the manner in which your personal information will be dealt with.
     Personal information for the purposes of this document means all information specific to you,
    which is provided to us through the use of our platforms. In other words, information that
    identifies you. This includes but is not limited to the following personal information that you may
    provide to us when registering for an account on our platforms or when contacting us directly:</p>
<p>• Your name and surname;</p>
<p>• RSA identity number or date of birth;</p>
<p>• Contact numbers;</p>
<p>• E-mail address.</p>
<p>• IMEI/Serial Number</p>
<h2>Consent</h2>
<p>By using our platforms, you hereby consent to our Privacy Policy and agree to its terms. By using
    any of the services on our app you hereby consent that we may send you promotional material or
    details which we think may be of interest to you. Please note that you will not be allowed to use
    the services unless you consent to us using your information for marketing purposes in the
    future.
</p>
<h2>How we use your information</h2>
<p>We use the information we collect in various ways, including to:</p>
<p>• Provide, operate, and maintain our website</p>
<p>• Improve, personalize, and expand our website</p>
<p>• Understand and analyse how you use our website</p>
<p>• Develop new products, services, features, and functionality</p>
<p>• Communicate with you, either directly or through one of our partners, including for
    customer service, to provide you with updates and other information relating to the
    website, and for marketing and promotional purposes</p>
<p>• Send you emails</p>
<p>• Find and prevent fraud</p>
<h2>Automatically Collected Information</h2>
<p>In addition, the Application may collect certain information automatically, including, but not limited
    to, the type of mobile device you use, your mobile devices unique device ID, the IP address of
    your mobile device, your mobile operating system, the type of mobile Internet browsers you use,
    information about the phone you are using and information about the way you use the
    Application.
     
    When you visit the application, we may use GPS technology (or other similar technology) to
    determine your current location in order to determine the city you are located within. We will not
    share your current location with other users or partners.
     
    If you do not want us to use your location for the purposes set forth above, you should turn off
    the location services for the mobile application located in your account settings or in your mobile
    phone settings and/or within the mobile application.
    We use third-party analytics tools to help us measure traffic and usage trends for the Service.
    These tools collect information sent by your device or our Service, including the web pages you
    visit, add-ons, and other information that assists us in improving the Service. We collect and use
    this analytics information with analytics information from other Users so that it cannot reasonably
    be used to identify any particular individual User.
     
    Do third parties see and/or have access to information obtained by the Application?
    Yes. We will share your information with third parties only in the ways that are described in this
    privacy statement.
     
    We may disclose User Provided and Automatically Collected Information:
    As required by law, such as to comply with a subpoena, or similar legal process; when we
    believe in good faith that disclosure is necessary to protect our rights, protect your safety or the
    safety of others, investigate fraud, or respond to a government request; with our trusted services
    providers who work on our behalf, do not have an independent use of the information we
    disclose to them, and have agreed to adhere to the rules set forth in this privacy statement.</p>
<h2>Data Retention Policy, Managing Your Information</h2>
<p>There may be links from the Service, or from communications you receive from the Service, to
    third-party web sites or features. There may also be links to third-party web sites or features in
    images or comments within the Service. The Service also includes third-party content that we do
    not control, maintain or endorse. Functionality on the Service may also permit interactions
    between the Service and a third-party web site or feature, including applications that connect the
    Service or your profile on the Service with a third-party web site or feature. For example, the
    Service may include a feature that enables you to share Content from the Service or your
    Content with a third party, which may be publicly posted on that third party&#39;s service or
    application.</p>
<h2>Data protection:</h2>
<p>We will retain User Provided data for as long as you use the Application and for a reasonable
    time thereafter. We will retain Automatically Collected information for up to 72 months and
    thereafter may store it in aggregate. If you’d like us to delete User Provided Data that you have
    provided via the Application, please contact us at info@ownedby.co.za and we will respond in a
    reasonable time. Please note that some or all of the User Provided Data may be required in
    order for the Application to function properly.</p>
<h2>Security</h2>
<p>We are concerned about safeguarding the confidentiality of your information. We provide
    physical, electronic, and procedural safeguards to protect information we process and maintain.
    For example, we limit access to this information to authorized employees and contractors who
    need to know that information in order to operate, develop or improve our Application. Please be
    aware that, although we endeavour to provide reasonable security for information we process
    and maintain, no security system can prevent all potential security breaches.
     
    Your privacy is important to us. We will therefore not sell, rent or provide your personal
    information to unauthorised entities or other third parties, for their independent use, without your
    consent, excluding in app information relating to your insurance/ asset activity.
     
    If at any stage, after you have given us your consent, you no longer wish that we use your
    personal information, you may at any stage withdraw your consent by uninstalling the
    Ownedby app from your mobile device and notify us either telephonically or by email of
    the withdrawal of your consent.
     
    We have the highest regard for the privacy of the personal information obtained through the use
    of our app, and therefore will only use the personal information obtained subject to the Terms
    and Conditions of this agreement and for the purpose for which it was collected and only for the
    purposes of positively identifying the user when the app is accessed as well as in order to quote
    you.
     
    We value the information you chose to provide and which we collect from you and will take
    reasonable steps to protect your personal information from loss, misuse or unauthorised
    alteration.
    The information we maintain concerning you is stored in databases that have built-in safeguards
    to ensure the privacy and confidentiality of that information.
     
    In this regard however, we cannot guarantee the security of any personal information that you
    disclose through our app. You therefore accept the inherent risk of providing information when
    using our app and will not hold us, our directors, employees or agents responsible for any breach
    of security.
     
    The following are the instances when we will be entitled to disclose the personal information
    obtained from you:</p>
<p>• When any regulatory authority for the various financial sectors requests same;</p>
<p>• To comply with any regulation passed under relevant legislation, or any legal process;</p>
<p>• To enforce and protect our rights and property(including intellectual property) ;</p>
<p>• When you have expressly authorised us to do so.</p>
<p>Please ensure that you have read and understood the Terms and Conditions of this Privacy
    Policy before you provide us with your personal information.
    We reserve the right in our sole discretion to amend this Privacy Policy from time to time. You
    agree to review the Privacy Policy whenever you visit our app for any such amendments. Save
    as expressly provided to the contrary in this Privacy Policy, the amended version of the Privacy
    Policy shall supersede and replace all previous versions thereof.</p>
<h2>GDPR Data Protection Rights</h2>
<p>We would like to make sure you are fully aware of all of your data protection rights. Every user is
    entitled to the following:</p>
<p>• The right to access – You have the right to request copies of your personal data. We may
    charge you a small fee for this service.</p>
<p>• The right to rectification – You have the right to request that we correct any information
    you believe is inaccurate. You also have the right to request that we complete the
    information you believe is incomplete.</p>
<p>• The right to erasure – You have the right to request that we erase your personal data,
    under certain conditions.</p>
<p>• The right to restrict processing – You have the right to request that we restrict the
    processing of your personal data, under certain conditions.</p>
<p>• The right to object to processing – You have the right to object to our processing of your
    personal data, under certain conditions.</p>
<p>• The right to data portability – You have the right to request that we transfer the data that
    we have collected to another organization, or directly to you, under certain conditions.</p>
<p>If you make a request, we have one month to respond to you. If you would like to exercise any of
    these rights, please contact us on info@ownedby.co.za.</p>
<h2>Log Files</h2>
<p>Ownedby follows a standard procedure of using log files. These files log visitors when they visit
    websites. All hosting companies do this and a part of hosting services&#39; analytics. The information
    collected by log files include internet protocol (IP) addresses, browser type, Internet Service
    Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks.
    These are not linked to any information that is personally identifiable. The purpose of the
    information is for analysing trends, administering the site, tracking users&#39; movement on the
    website, and gathering demographic information.</p>
<h2>Cookies and Web Beacons</h2>
<p>Like any other website, Ownedby uses &#39;cookies&#39;. These cookies are used to store information
    including visitors&#39; preferences, and the pages on the website that the visitor accessed or visited.
    The information is used to optimize the users&#39; experience by customizing our web page content
    based on visitors&#39; browser type and/or other information.
    For more general information on cookies, please read &quot;What Are Cookies&quot; from Cookie Consent.</p>
<h2>Google DoubleClick DART Cookie</h2>
<p>Google is one such third-party vendor on our site. It also uses cookies, known as DART
    cookies, to serve ads to our site visitors based upon their visit to www.website.com and other
    sites on the internet. However, visitors may choose to decline the use of DART cookies by
    visiting the Google ad and content network Privacy Policy at the following URL
    – https://policies.google.com/technologies/ads</p>
<h2>Our Advertising Partners</h2>
<p>advertisers on our site may use cookies and web beacons. Our current advertising
    partners are listed below. Each of our advertising partners has their own Privacy Policy for their
    policies on user data. For easier access, we hyperlinked to their Privacy Policies below.</p>
<p>• Google - https://policies.google.com/technologies/ads</p>
<h2>Advertising Partners Privacy Policies</h2>
<p>You may consult this list to find the Privacy Policy for each of the advertising partners of
    Ownedby.
    Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web
    Beacons that are used in their respective advertisements and links that appear on Ownedby,
    which are sent directly to users&#39; browser. They automatically receive your IP address when this
    occurs. These technologies are used to measure the effectiveness of their advertising
    campaigns and/or to personalize the advertising content that you see on websites that you visit.
    Note that Ownedby has no access to or control over these cookies that are used by third-party
    advertisers.</p>
<h2>Third Party Privacy Policies</h2>
<p>Ownedby&#39;s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising
    you to consult the respective Privacy Policies of these third-party ad servers for more detailed
    information. It may include their practices and instructions about how to opt-out of certain
    options. 
    You can choose to disable cookies through your individual browser options. To know more
    detailed information about cookie management with specific web browsers, it can be found at the
    browsers&#39; respective websites.</p>
<h2>Children&#39;s Information</h2>
<p>Another part of our priority is adding protection for children while using the internet. We
    encourage parents and guardians to observe, participate in, and/or monitor and guide their
    online activity.
    Ownedby does not knowingly collect any Personal Identifiable Information from children under
    the age of 13. If you think that your child provided this kind of information on our website, we
    strongly encourage you to contact us immediately and we will do our best efforts to promptly
    remove such information from our records.</p>
<h2>South African Law Applies</h2>
<p>South African law applies to this policy and only the courts of the Republic of South Africa may
    deal with any dispute in respect of this policy.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>


