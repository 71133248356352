<ngx-spinner></ngx-spinner>

<div [class.fade]="onComp">
  <div></div>
</div>

<div class="desktopContainer" [class.fade2]="onComp2">
  <div id="scrollHome"></div>

  <div id="navbar" class="navNew">
    <div><img id="logo" src="/assets/logoContainer.png" /></div>

    <div class="topPadding">
      <a id="hm" (click)="scrollToHome()">Home</a>
      <a id="scrns" (click)="scrollToScreens()">Screens</a>
      <a id="ftrs" (click)="scrollToFeatures()">Features</a>
      <a id="cntcts" (click)="scrollToContactUs()">Contact&nbsp;Us</a>

      <button
        mat-flat-button
        color="primary"
        class="radius fontUse button2"
        (click)="toSignIn()"
      >
        Sign-In/Register
      </button>
    </div>
  </div>

  <div style="font-size: 30px">
    <!--<mat-toolbar class="sticky">
            <span><img class="toolBarLogo" src="/assets/OwnedbyBlack.png"></span>
            <span class="example-spacer"></span>
            <span>
                <div class="toolbarFlex">
                    <a class="p2" id="ancHome">Home</a>
                    <a id="ancScreens" class="p2">Screens</a>
                    <a class="p2" id="ancFeatures">Features</a>
                    <a class="p2" id="ancContact">Contact Us</a>

                    <button mat-flat-button color="primary" class="radius fontUse button2"
                        (click)="toSignIn()">Sign-In/Register</button>
                </div>



            </span>
        </mat-toolbar> -->
    <div class="backgroundDivTop">
      <div class="topDivControl">
        <h1 class="firstPicTextHead">
          Identify it, Trace it, that's Ownership
        </h1>

        <h2 class="firstPicTextfooter">
          If you own it, ensure its marked as yours
        </h2>
      </div>
    </div>
    <div style="text-align: center" class="picControl">
      <h3 class="Orangetext">HOW IT WORKS</h3>
      <p class="screensSmallText2">
        Ownedby is an interactive asset managenment tool for your home and
        business devices, that digitaly links these valuables to you as the
        owner. This ensures that your valuables exist online, with a unique and
        traceable identity. Once you have linked your valuables, you will have
        the ability to assign digi-tags that let the world know whether the item
        is for sale, lost or stolen.
      </p>
      <div class="ghost"></div>

      <p class="Orangetext">
        If you own it, mark it as yours and we will tell the world.
      </p>
      <div class="ghost"></div>
      <h1 class="pageHeaders" style="text-align: center">
        Unique & Secure Digital Identity
      </h1>
      <div style="text-align: center">
        <div class="blackAccent2"></div>
      </div>
      <div>
        <p></p>
      </div>

      <p class="screensSmallText2">
        It is important to ensure our appliances and devices to be searchable
        online, Ownedby makes this possible using your valuables IMEI or Serial
        number
      </p>

      <div class="ghost"></div>

      <p class="Orangetext">What is an IMEI?</p>
      <div class="ghost"></div>

      <p class="screensSmallText2">
        The International Mobile Equipment Identity (IMEI) number is a unique 15
        digit long identification or serial number that all mobile phones and
        smartphones have. Dial *#06# on your device to discover your IMEI
      </p>
    </div>

    <div class="qcheckBox" style="font-size: 17px">
      <form
        class="parent"
        [formGroup]="quickCheckForm"
        (ngSubmit)="quickcheck()"
      >
        <div>
          <mat-form-field appearance="legacy" class="formWidth">
            <mat-label color="primary" class="inpuFont"
              >Enter an IMEI number and do a QuickCheck</mat-label
            >
            <input
              matInput
              formControlName="imeiSubmitted"
              id="imeiField"
              #txtInput
            />
            <mat-error *ngIf="hasError('imeiSubmitted', 'required')"
              >IMEI is required</mat-error
            >
            <mat-error *ngIf="hasError('imeiSubmitted', 'pattern')"
              >IMEI number must be 15 digits
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <input
            type="image"
            class="qCheckPic"
            name="submit"
            src="/assets/qcheck.PNG"
            alt="Submit"
          />
        </div>
      </form>
    </div>
    <div class="ghost"></div>

    <!---------------------------------------------------------------------------------->

    <div id="scrollScreens"></div>
    <div class="gridMargin">
      <mat-grid-list cols="2" rowHeight="1:1.5">
        <mat-grid-tile>
          <img
            class="deviceScreens"
            src="/assets/device2.png"
            style="text-align: center"
          />
        </mat-grid-tile>

        <mat-grid-tile>
          <mat-grid-list
            class="gridCeption"
            cols=""
            rowHeight="1:1.5"
          ></mat-grid-list>
          <div class="tileFlex">
            <div>
              <h1 class="screensTextHead">
                Device for sale,<br />
                lost or stolen?
              </h1>
            </div>
            <div class="screensAccent"></div>
            <div>
              <p class="screensText">
                Use our Digi-tags to tag the device's IMEI as for sale, lost or
                stolen and ensure the status of your device is visible to anyone
                who does a QuickCheck on it. Our QuickCheck fetches a devices
                specific information from the worlds largest TAC (Time
                Allocation Code) database.
              </p>
            </div>
          </div>
        </mat-grid-tile>

        <mat-grid-tile>
          <div class="tileFlex">
            <div>
              <h1 class="screensTextHead">Claim ownership</h1>
            </div>
            <div class="screensAccent"></div>
            <div>
              <p class="screensText">
                Discover your devices profile on Ownedby, claim ownership by
                creating your profile and linking the IMEI/Serial number to
                yourself. Digitaly own your life-companion and your other
                valuables.
              </p>
            </div>
          </div>
        </mat-grid-tile>

        <mat-grid-tile>
          <img
            class="deviceScreens"
            src="/assets/device.PNG"
            style="text-align: center"
          />
        </mat-grid-tile>

        <mat-grid-tile>
          <img
            class="deviceScreens"
            src="/assets/device1.png"
            style="text-align: center"
          />
        </mat-grid-tile>

        <mat-grid-tile>
          <div class="tileFlex">
            <div>
              <h1 class="screensTextHead">Validate Ownership</h1>
            </div>
            <div class="screensAccent"></div>
            <div>
              <p class="screensText">
                Buying or selling valuables in the second-hand market? Validate
                that you are buying from the rightful owner, and ensure that
                potential buyers can connect with you. How? It all starts with a
                QuickCheck of your IMEI/Serial number. This will reveal the for
                sale status to the buyer and confirm rightfull ownership of the
                device. agree on the sale and transfer ownership status from
                your profile to the buyers'.
              </p>
            </div>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </div>

    <div id="scrollFeatures"></div>

    <div style="text-align: center" class="noMargin">
      <h3 class="Orangetext PaddingTop">FEATURES</h3>
      <h1 class="pageHeaders">Check out Ownedby</h1>
      <div style="text-align: center">
        <div class="blackAccent2"></div>
      </div>
      <p class="screensSmallText2">
        In a world where we can Google almost anything Uber around and Shazam
        any song we don't know why can't we check who it is owned by? Ownedby is
        software designed for individuals, households and businesses who will
        benefit from the following:
      </p>
    </div>

    <div class="ghost"></div>
    <app-carousel></app-carousel>

    <div class="backgroundDivBottom picControl2" style="text-align: center">
      <h1 class="bottomPicHeader">Who owns your device?</h1>
      <p class="bottomPicFooter">
        Now you can check and validate the ownership status of a device
      </p>

      <button
        mat-flat-button
        color="primary"
        class="radius fontUse"
        (click)="setFocus()"
      >
        QuickCheck
      </button>
    </div>
  </div>

  <!---------------------------------------------------------------->

  <div id="scrollContactUs" class="ghost"></div>

  <div class="contactMain">
    <div style="text-align: center">
      <p class="Orangetext4">CONTACT US</p>
    </div>
    <div style="text-align: center" class="pageHeadersB">
      Get In Touch With Us
    </div>
    <div class="ghost"></div>
    <div class="contactUsFlex">
      <div class="divForm contactUsFlex">
        <form [formGroup]="contactUsForm" (ngSubmit)="onContact()">
          <div class="topTwo">
            <div>
              <div>
                <input
                  class="nameForm"
                  id="name"
                  type="text"
                  formControlName="name"
                  placeholder="Name"
                />
              </div>
            </div>
            <div>
              <div>
                <input
                  class="nameForm"
                  id="email"
                  type="email"
                  formControlName="email"
                  placeholder="Email"
                />
              </div>
            </div>
          </div>
          <div>
            <input
              class="messageform"
              id="message"
              type="text"
              formControlName="message"
              placeholder="Message"
            />
          </div>
          <div style="text-align: center">
            <button mat-flat-button class="button4" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
      <div>
        <p class="textContactUs">Ownedby Technologies (Pty) Ltd</p>
        <p class="textContactUs">Tshimologong Digital Precinct</p>
        <p class="textContactUs">41 Juta Street, Johannessburg 2006</p>
        <p class="textContactUs">Email: info@ownedby.co.za</p>
        <p class="textContactUs"></p>
      </div>
    </div>
  </div>
  <div class="ghost"></div>
  <div style="text-align: center">
    <p class="Orangetext4">OUR PARTNERS</p>
  </div>
  <div class="ghost"></div>
  <div class="tbt">
    <div class="contactMain">
      <p class="textContactUs">THE INNOVATION HUB SOUTH AFRICA</p>
      <img class="contactUsPics" src="/assets/innohub.png" />
    </div>
    <div class="contactMain">
      <p class="textContactUs">DEPARTMENT OF SCIENCE & INNOVATION</p>
      <img class="contactUsPics" src="/assets/dep.png" />
    </div>
    <div class="contactMain">
      <p class="textContactUs">TECHNOLOGY INNOVATION AGENCY</p>
      <img class="contactUsPics" src="/assets/tlogo.png" />
    </div>
    <div class="contactMain">
      <p class="textContactUs">HIIL JUSTICE ACCELERATOR</p>
      <img class="contactUsPics" src="/assets/hiil.png" />
    </div>
    <div class="contactMain">
      <p class="textContactUs">GSM ASSOCIATION</p>
      <img class="contactUsPics" src="/assets/gsmalogo.png" />
    </div>
  </div>
</div>

<!----------------------------------------------------------------------->

<div class="flex-containerMobile">
  <div id="scrollHomeM"></div>

  <div style="font-size: 30px">
    <div class="mobileNav">
      <div class="mobileNavInline">
        <!--<button type="button" aria-label="Toggle sidenav" mat-icon-button
                                (click)="sidenav.toggle()">
                                <mat-icon>menu</mat-icon>
                            </button>
                    <button mat-button #menub [matMenuTriggerFor]="menu">
                        <mat-icon>menu</mat-icon>
                    </button> -->

        <div>
          <img class="mobileNavLogo" src="/assets/OwnedbyBlack.png" />
        </div>
        <div class="">
          <button
            mat-flat-button
            color="primary"
            class="radius fontUse button2"
            (click)="toSignIn()"
          >
            Sign-In/Register
          </button>
        </div>
      </div>
    </div>

    <div class="backgroundDivTop">
      <div class="topDivControl">
        <h1 class="firstPicTextHead">
          Identify it, Trace it, that's Ownership
        </h1>

        <h2 class="firstPicTextfooter">
          If you own it, ensure its marked as yours
        </h2>
      </div>
    </div>
    <div style="text-align: center" class="picControl">
      <h3 class="Orangetext">HOW IT WORKS</h3>
      <p class="screensSmallText2">
        Ownedby is an interactive asset managenment tool for your home and
        business devices, that digitaly links these valuables to you as the
        owner. This ensures that your valuables exist online, with a unique and
        tracable identity. Once you have linked your valuables, you will have
        the ability to assign digi-tags that let the world know whether the item
        is for sale, lost or stolen.
      </p>
      <div class="ghost"></div>

      <p class="Orangetext">
        If you own it, mark it as yours <br />and we will tell the world.
      </p>
      <div class="ghost"></div>
      <h1 class="pageHeaders" style="text-align: center">
        Unique & Secure Digital Identity
      </h1>
      <div style="text-align: center">
        <div class="blackAccent2"></div>
      </div>
      <div>
        <p></p>
      </div>

      <p class="screensSmallText2">
        It is important to ensure our appliances and devices to be searchable
        online, Ownedby makes this possible using your valuables IMEI or Serial
        number
      </p>

      <p class="Orangetext">What is an IMEI?</p>

      <p class="screensSmallText2">
        The International Mobile Equipment Identity (IMEI) number is a unique 15
        digit long identification or serial number that all mobile phones and
        smartphones have. Dial *#06# on your device to discover your IMEI
      </p>
    </div>

    <div class="qcheckBox" style="font-size: 16px">
      <form
        class="parent"
        [formGroup]="quickCheckForm"
        (ngSubmit)="quickcheck()"
      >
        <div>
          <mat-form-field appearance="legacy" class="formWidth">
            <mat-label>Enter an IMEI number and do a QuickCheck</mat-label>
            <input
              matInput
              formControlName="imeiSubmitted"
              id="imeiField"
              #txtInput
            />
            <mat-error *ngIf="hasError('imeiSubmitted', 'required')"
              >IMEI is required</mat-error
            >
            <mat-error *ngIf="hasError('imeiSubmitted', 'pattern')"
              >IMEI number must be 15 digits
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <input
            type="image"
            class="qCheckPic"
            name="submit"
            src="/assets/qcheck.PNG"
            alt="Submit"
          />
        </div>
      </form>
    </div>
    <div class="ghost"></div>

    <!---------------------------------------------------------------------------------->

    <div id="scrollScreensM"></div>

    <div class="flex-containerMobile" style="text-align: center">
      <h1 class="pageHeaders" style="text-align: center">
        Device for sale,<br />
        lost or stolen?
      </h1>
      <div style="text-align: center">
        <div class="blackAccent2"></div>
      </div>
      <div style="text-align: center">
        <p class="screensSmallText" style="text-align: center">
          Use our Digi-tags to tag the device's IMEI as for sale, lost or stolen
          and ensure the status of your device is visible to anyone who does a
          QuickCheck on it. Our QuickCheck fetches a devices specific
          information from the worlds largest TAC (Time Allocation Code)
          database.
        </p>
      </div>
      <div>
        <img
          class="deviceScreens"
          src="/assets/device.PNG"
          style="text-align: center"
        />
      </div>

      <h1 class="pageHeaders" style="text-align: center">Claim ownership</h1>
      <div style="text-align: center">
        <div class="blackAccent2"></div>
      </div>
      <p class="screensSmallText">
        Discover your devices profile on Ownedby, claim ownership by creating
        your profile and linking the IMEI/Serial number to yourself. Digitaly
        own your life-companion and your other valuables.
      </p>

      <div>
        <img
          class="deviceScreens"
          src="/assets/device1.png"
          style="text-align: center"
        />
      </div>

      <h1 class="pageHeaders" style="text-align: center">Validate Ownership</h1>
      <div style="text-align: center">
        <div class="blackAccent2"></div>
      </div>
      <p class="screensSmallText">
        Buying or selling valuables in the second-hand market? Validate that you
        are buying from the rightful owner, and ensure that potential buyers can
        connect with you. How? It all starts with a QuickCheck of your
        IMEI/Serial number. This will reveal the for sale status to the buyer
        and confirm rightfull ownership of the device. agree on the sale and
        transfer ownership status from your profile to the buyers'.
      </p>
      <div>
        <img
          class="deviceScreens"
          src="/assets/device2.png"
          style="text-align: center"
        />
      </div>
    </div>

    <div id="scrollFeaturesM"></div>

    <div style="text-align: center" class="noMargin">
      <h3 class="Orangetext PaddingTop">FEATURES</h3>
      <h1 class="pageHeaders">Check out Ownedby</h1>
      <div style="text-align: center">
        <div class="blackAccent2"></div>
      </div>
      <p class="screensSmallText2">
        In a world where we can Google almost anything Uber around and Shazam
        any song we don't know why can't we check who it is owned by? Ownedby is
        software designed for individuals, households and businesses who will
        benefit from the following:
      </p>
    </div>
    <div>
      <app-accordian></app-accordian>
    </div>

    <div class="ghost"></div>

    <div class="backgroundDivBottom picControl2" style="text-align: center">
      <h1 class="bottomPicHeader">Who owns your device?</h1>
      <p class="bottomPicFooter">
        Now you can check and validate the ownership status of a device
      </p>

      <button
        mat-flat-button
        color="primary"
        class="radius fontUse"
        (click)="setFocus()"
      >
        QuickCheck
      </button>
    </div>
  </div>

  <!---------------------------------------------------------------->

  <div id="scrollContactUsM" class="ghost"></div>

  <div class="contactMain">
    <div style="text-align: center">
      <p class="Orangetext4">CONTACT US</p>
    </div>
    <div style="text-align: center" class="pageHeadersB">
      Get In Touch With Us
    </div>
    <div class="ghost"></div>
    <div class="contactUsFlex">
      <div class="divForm contactUsFlex">
        <form [formGroup]="contactUsForm" (ngSubmit)="onContact()">
          <div class="topTwo">
            <div>
              <div>
                <input
                  class="nameForm"
                  id="name"
                  type="text"
                  formControlName="name"
                  placeholder="Name"
                />
              </div>
            </div>
            <div>
              <div>
                <input
                  class="nameForm"
                  id="email"
                  type="email"
                  formControlName="email"
                  placeholder="Email"
                />
              </div>
            </div>
          </div>
          <div>
            <input
              class="messageform"
              id="message"
              type="text"
              formControlName="message"
              placeholder="Message"
            />
          </div>
          <div style="text-align: center">
            <button mat-flat-button class="button4" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
      <div>
        <p class="textContactUs">Ownedby Technologies (Pty) Ltd</p>
        <p class="textContactUs">Tshimologong Digital Precinct</p>
        <p class="textContactUs">41 Juta Street, Johannessburg 2006</p>
        <p class="textContactUs">Email: info@ownedby.co.za</p>
        <p class="textContactUs"></p>
      </div>
    </div>
  </div>
  <div class="ghost"></div>
  <div style="text-align: center">
    <p class="Orangetext4">OUR PARTNERS</p>
  </div>
  <div class="ghost"></div>
  <div class="tbt">
    <div class="contactMain">
      <p class="textContactUs">THE INNOVATION HUB SOUTH AFRICA</p>
      <img class="contactUsPics" src="/assets/innohub.png" />
    </div>
    <div class="contactMain">
      <p class="textContactUs">DEPARTMENT OF SCIENCE & INNOVATION</p>
      <img class="contactUsPics" src="/assets/dep.png" />
    </div>
    <div class="contactMain">
      <p class="textContactUs">TECHNOLOGY INNOVATION AGENCY</p>
      <img class="contactUsPics" src="/assets/tlogo.png" />
    </div>
    <div class="contactMain">
      <p class="textContactUs">HIIL JUSTICE ACCELERATOR</p>
      <img class="contactUsPics" src="/assets/hiil.png" />
    </div>
    <div class="contactMain">
      <p class="textContactUs">GSM ASSOCIATION</p>
      <img class="contactUsPics" src="/assets/gsmalogo.png" />
    </div>
  </div>
</div>
