import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DeviceInfoDialogComponent } from 'src/app/device-info-dialog/device-info-dialog.component';
import { ComsServiceService } from 'src/app/services/coms-service.service';
import { DeviceStateService } from 'src/app/services/device-state.service';
import { Invites } from '../../interfaces/invites';


@Component({
  selector: 'app-chat-invite-dialog',
  templateUrl: './chat-invite-dialog.component.html',
  styleUrls: ['./chat-invite-dialog.component.css']
})
export class ChatInviteDialogComponent implements OnInit {

  public inviteData: Invites;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Invites,
    private dialogRef: MatDialogRef<ChatInviteDialogComponent>,
    private dialog: MatDialog,
    public stateService: DeviceStateService,
    public comService: ComsServiceService,
    private firestore: AngularFirestore,

  ) {
    this.inviteData = data;
   }

  ngOnInit(): void {
    
  }

  onAccept() {

    this.firestore.collection("invitesAccepted").add({

      fromUser: this.inviteData.fromUser,
      toUser: this.inviteData.toUser,
      date: new Date(),
      device: this.inviteData.device
    })
    this.dialog.closeAll()
  }

  onClose()  {
    this.dialog.closeAll();
  }



}
