import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.css']
})
export class ResetPasswordDialogComponent implements OnInit {
  

  ForgotPassForm!: FormGroup;

  constructor(
    public authService: AuthenticationService,
    public fb: FormBuilder,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.ForgotPassForm = this.fb.group({
      passwordResetEmail: ['', Validators.required],
    });
  }

  onForgotPass(value: { passwordResetEmail: string; }) {
    this.authService.ForgotPassword(value.passwordResetEmail);
  }

  close() {
    this.dialog.closeAll();
  }


}
