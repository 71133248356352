import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from "src/app/services/authentication.service";

import {  Observable } from 'rxjs';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-landing-quickcheck-dialog',
  templateUrl: './landing-quickcheck-dialog.component.html',
  styleUrls: ['./landing-quickcheck-dialog.component.css']
})
export class LandingQuickcheckDialogComponent implements OnInit {

  checkForm = this.fb.group({
    imei: new FormControl(''),
    source: new FormControl(''),
  });

  emailDeviceReportForm = this.fb.group({
    reportEmail: new FormControl(''),
  });


  public quickcheckResponse: any;



  constructor(
    public authService: AuthenticationService,
    private fb: FormBuilder,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {

  }

  closeDialog() {
    this.dialog.closeAll()
  }



  onCheck() {
    var input: string = this.checkForm.value.imei
    
    return this.authService.quickcheck(input)
      .subscribe((result) => {
        console.log(result);
        this.quickcheckResponse = result;
        console.log(this.quickcheckResponse.model);
        if (this.quickcheckResponse.status === "SUCCESS") {
          console.log('Found device');
        }else if (this.quickcheckResponse.status === "NOT_FOUND") {
          console.log('Did not find device');
        }else {
          console.log('Something went wrong');
        }
      })
  }


  onSubmitEmailForReport() {}

}
