import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-sign-up-dialog',
  templateUrl: './sign-up-dialog.component.html',
  styleUrls: ['./sign-up-dialog.component.css']
})
export class SignUpDialogComponent implements OnInit {


  

  checked1 = true;
  disabled: boolean = false;

  registerForm = this.fb.group({
    firstNameSubmitted: new FormControl(''),
    lastNameSubmitted: new FormControl(''),
    emailSubmitted: new FormControl(''),
    passwordSubmitted: new FormControl(''),
    confirmPasswordSubmitted: new FormControl(''),
  });

  constructor(
    public authService: AuthenticationService,
    private fb: FormBuilder,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  classApplied = true;
  classApplied2 = false;

  toggleClass() {
    this.classApplied = !this.classApplied;
    this.classApplied2 = !this.classApplied2;
  }

  onRegisterUser() {
    var firstNameSubmitted: string = this.registerForm.value.firstNameSubmitted;
    var lastNameSubmitted: string = this.registerForm.value.lastNameSubmitted;
    var emailSubmitted: string = this.registerForm.value.emailSubmitted;
    var passwordSubmitted: string = this.registerForm.value.passwordSubmitted;
    var confirmPasswordSubmitted: string = this.registerForm.value.confirmPasswordSubmitted;
    
    this.authService.signUp(firstNameSubmitted, lastNameSubmitted, emailSubmitted, passwordSubmitted, confirmPasswordSubmitted);
  }

  agree() {
    window.alert("You have to agree with terms before registering")
  }

  close() {
    this.dialog.closeAll();
  }

}


