
<p class="Orangetext4">OUR PARTNERS</p>

<div class="partnerFlex">
    <div class="partnerInstance">
        <p class="textContactUs" >THE INNOVATION HUB SOUTH AFRICA</p>
        <img class="imageControl" src="/assets/innohub.png" />
    
    </div>
    <div class="partnerInstance">
        <p class="textContactUs">DEPARTMENT OF SCIENCE & INNOVATION</p>
        <img src="/assets/dep.png" />
        
    </div>
    <div class="partnerInstance">
        <p class="textContactUs">TECHNOLOGY INNOVATION AGENCY</p>
        <img src="/assets/tlogo.png" />
        
    </div>
    <div class="partnerInstance">
        <p class="textContactUs">HIIL JUSTICE ACCELERATOR</p>
        <img src="/assets/hiil.png" />
        
    </div>
    <div class="partnerInstance">
        <p class="textContactUs">GSM ASSOCIATION</p>
        <img src="/assets/gsmalogo.png" />
        
    </div>
</div>

