<div class="cardSize">
    <div class="exitButtonRight">
        <div>
            <img class="logo" src="/assets/OwnedbyBlackChatBack.png">
        </div>
        <div>
            <mat-icon class="exitButton" (click)="close()">close</mat-icon>
        </div>
    </div>

    <div>
        <form [formGroup]="SAidForm" (ngSubmit)="onSubmit()">
            <mat-form-field appearance="legacy" class="formWidth" style="text-align:center;">
                <mat-label>Please Enter your ID/Passport Number</mat-label>
                <input matInput formControlName="idSubmitted" id="SAID" class="formWidth">

            </mat-form-field>
            <div class="ghost"></div>
            <div class="ghost"></div>
            <div style="text-align: center;">
                <button mat-flat-button class="generalButton" type="submit">Submit</button>
            </div>
        </form>
    </div>
    


</div>

