import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Coms } from 'src/app/interfaces/coms';


@Component({
  selector: 'app-coms-dialog',
  templateUrl: './coms-dialog.component.html',
  styleUrls: ['./coms-dialog.component.css']
})
export class ComsDialogComponent implements OnInit {

  public comsData: Coms;


  constructor(

    @Inject(MAT_DIALOG_DATA) public data: Coms,
    private dialogRef: MatDialogRef<ComsDialogComponent>,
    private dialog: MatDialog,
  ) { 
    this.comsData = data;
  }


  close() {
    this.dialog.closeAll();
  }

  ngOnInit(): void {
  }

}
