<div class="carouselMaster">


  <div></div>
  <mat-icon class="buttonRight" (click)="hitLeft()">chevron_left</mat-icon>
  <div></div>

  <div class="carouselPanelMain">
    <div id="mainContent1">
      <div class="card1">
        <div>
          <mat-icon class="iconColor1">list</mat-icon>
          <div class="cardHead">Complete Register</div>
        </div>

        <div></div>
        <div></div>

        <div class="cardDescription">
          A full register of your appliances & devices, with their unique
          identities (IMEI/Serial Number)
        </div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="card1">
        <div>
          <mat-icon class="iconColor2">devices</mat-icon>
          <div class="cardHead">Asset Allocation</div>
        </div>

        <div></div>
        <div class="cardDescription">
          Assign specific assets to users. E.g. Businesses assigning company
          cellphones/laptops to employees. Or a device from a parent to a
          dependent
        </div>
        <div></div>
        <div></div>
      </div>
      <div class="card1">
        <div>
          <mat-icon class="iconColor3">search</mat-icon>
          <div class="cardHead">QuickCheck</div>
        </div>

        <div></div>
        <div class="cardDescription">
          A search engine that retrieves device specific information including
          specifications, and the Digi-Tag status applied by the owner. Personal
          information of the owner is always kept confidential
        </div>
        <div></div>
      </div>
    </div>

    <div id="mainContent2">
      <div class="card2">
        <div>
          <mat-icon class="iconColor4">local_offer</mat-icon>
          <div class="cardHead">Digi-Tags</div>
        </div>

        <div></div>
        <div class="cardDescription">
          Owners only can apply status labels (such as Active | For-Sale | Lost
          | Stolen. These statuses are publicly accessible when an item's unique
          identity is used in a QuickCheck
        </div>
        <div></div>
        <div></div>
      </div>
      <div class="card2">
        <div>
          <mat-icon class="iconColor1">forum</mat-icon>
          <div class="cardHead">ChatGateway</div>
        </div>

        <div></div>

        <div class="cardDescription">
          Connect with the listing owner, through our secure ChatGateway to
          authenticate that you are buying from the rightful owner, and initiate
          transfer of ownership of the devices profile
        </div>
        <div></div>
        <div></div>
      </div>
      <div class="card2">
        <div>
          <mat-icon class="iconColor2">dashboard</mat-icon>
          <div class="cardHead">Dashboards</div>
        </div>

        <div></div>
        <div class="cardDescription">
          Powerful dashboards listing all items linked to the owner (including
          those assigned to users. Owners can enjoy a summary of all
        </div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div id="mainContent3">
      <div class="card3">
        <div>
          <mat-icon class="iconColor3">check_circle_outline</mat-icon>
          <div class="cardHead">Device Validation</div>
        </div>
        <div></div>


        <div class="cardDescription">
          Validate whether a device was previously blacklisted or if it’s a
          clone or counterfeit against the worlds largest TAC database
        </div>
        <div></div>
        <div></div>
      </div>
      <div class="card3">
        <div>
          <mat-icon class="iconColor4">swap_horizontal_circle</mat-icon>
          <div class="cardHead">Authenticate & Transfer</div>
        </div>

        <div class="cardDescription">
          Owner authentication & transfer of ownership of devices digital
          profile from seller to buyer
        </div>
        <div></div>
        <div></div>
      </div>
      <div class="card3">
        <div>
          <mat-icon class="iconColor1">group_work</mat-icon>
          <div class="cardHead">Company Portal</div>
        </div>

        <div class="cardDescription">
          Custom portal with company colors & logo. Role based access control
          for sub accounts with trackable change logs e.g. immediate alert when
          user changes digi-tag
        </div>
        <div></div>
      </div>
    </div>
  </div>
  <div></div>

  <mat-icon class="buttonRight" (click)="hitRight()">chevron_right</mat-icon>
  <div></div>


</div>
<div class="paginatorWrap">
  <div id="paginator1"></div>
  <div id="paginator2"></div>
  <div id="paginator3"></div>
</div>
