import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { QuickCheckResult } from 'src/app/interfaces/QuickCheckResult';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-provide-email',
  templateUrl: './provide-email.component.html',
  styleUrls: ['./provide-email.component.css'],
})
export class ProvideEmailComponent implements OnInit {
  contactForm = this.fb.group({
    contactSubmitted: new FormControl('', [Validators.required]),
  });

  constructor(
    private fb: FormBuilder,
    private firestore: AngularFirestore,
    private userService: UserService,
    private afAuth: AngularFireAuth,

    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}


  






  onSubmit() {

    
    

    var notificationRef: any = this.userService.getUserLoggedIn();

    

    this.firestore
      .collection('users')
      .doc(notificationRef)
      .update({
        contact: this.contactForm.value.contactSubmitted,
      })
      .then((docRef: any) => {
        console.log('contact created');
        this.dialog.closeAll();
      });
  }

  close() {
    this.dialog.closeAll();
  }
}
