<ngx-spinner></ngx-spinner>


<div class="backgrnd fills">
  <div class="fills2 container">
    <mat-card
      class="cardD mat-elevation-z20 fills container"
      style="text-align: center"
    >
      <div>
        <div>
          <img class="cardImg divMarg" src="assets/OwnedbyBlack.png" />
        </div>
        
        <h2 class="fontMullish">Sign In</h2>
        <form [formGroup]="loginForm" (ngSubmit)="onSignIn()">
          <div>
            <mat-form-field class="forms form-group" appearance="legacy">
              <mat-label class="fontMullish">Username</mat-label>
              <input
                type="email"
                matInput
                class="form-control fontMullish"
                formControlName="email"
                placeholder="Enter Username"
                required
              />
            </mat-form-field>
          </div>
          <div class="ghost"></div>
          <ng-adsense [adClient]="'ca-pub-XXXXXXXXXX'" [pageLevelAds]="true"></ng-adsense>

          <div>
            <mat-form-field class="forms form-group" appearance="legacy">
              <mat-label class="fontMullish">Password</mat-label>
              <input
                type="password"
                matInput
                class="form-control"
                formControlName="password"
                placeholder="Enter Password"
                required
              />
            </mat-form-field>
          </div>
          <div class="ghost"></div>
          <div class="divMarg">
            <a
              class="cor"
              href="javascript:void(0)"
              (click)="openResetPassword()"
              >Forgot password?</a
            >
          </div>
          <button mat-flat-button color="primary" class="radius" type="submit">
            Login
          </button>
        </form>
        <p class="space">OR</p>
        <div>
          <button
            mat-flat-button
            color="primary"
            class="radius fontMullish"
            (click)="googleLogin()"
          >
            Login with Google
          </button>
        </div>
        <button
          mat-flat-button
          color="primary"
          class="radius fontMullish"
          (click)="openRegisterDialog()"
        >
          Register
        </button>
        <div class="ghost"></div>
      </div>
    </mat-card>
  </div>
</div>
