import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OverlaysService {

  constructor() { }

  public overlayShowing: boolean = false;

  activateOverL() {
    this.overlayShowing = !this.overlayShowing
  }

  isTrue() {
    this.overlayShowing = true
  }
}
