import { Component, OnInit } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  from,
  merge,
  Observable,
  forkJoin,
  observable,
  BehaviorSubject,
} from 'rxjs';
import { Subject } from 'rxjs';
import { isEmpty, last, mergeAll } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';
import { Chat } from '../interfaces/chat';
import { ChatStream } from '../interfaces/chat-stream';
import { Invites } from '../interfaces/invites';
import { Device } from '../interfaces/Device';
import { UserService } from '../services/user.service';
import { ChatInviteDialogComponent } from '../chat2/chat-invite-dialog/chat-invite-dialog.component';
import { range } from 'rxjs';
import { count } from 'rxjs/operators';
import { mergeMap, MergeMapOperator } from 'rxjs/internal/operators/mergeMap';
import { scheduled } from 'rxjs';

@Component({
  selector: 'app-chat2',
  templateUrl: './chat2.component.html',
  styleUrls: ['./chat2.component.css'],
})
export class Chat2Component implements OnInit {
  public defaultMessage: boolean = false;

  visibilityInstance = false;
  visibiltyContent = true;
  visibiltyDivider = true;
  noInstanceMessage = true;

  public chatsInstanceCollection:
    | AngularFirestoreCollection<ChatStream>
    | undefined;
  public chtInst: Observable<ChatStream[]> | undefined;

  public chatsContentCollection: AngularFirestoreCollection<Chat> | undefined;

  public chtCntBS!: BehaviorSubject<Chat[]>;
  public chtCnt!: Observable<Chat[]>;
  public chtCntArray!: Chat[];

  public localArray!: Observable<Chat[]>;
  public chtHeader: string = '___';

  public obsEmpty: string = 'wait';
  public obsEmpty2: string = 'wait';
  public pointer: string = 'wait';

  public result: any = 'nullo';

  public numero: any = 0;

  public showLoader: boolean = true;
  public showEmptyInbox: boolean = false;
  public showContent: boolean = false;

  public counting: any;

  public hexa: any = 'start';

  public screenBool: boolean = false;

  public instanceYourDevice: boolean = false;

  notificationRef: any = this.userService.getUserLoggedIn();

  chatUser: string | undefined;
  chatDevice: string | undefined;
  rmUser: string = 'unset';
  rmDevice: string | undefined;
  rmName: string | undefined;

  invToUser: string | undefined;
  invDevice: string | undefined;
  whoDevice: string | undefined;

  public ll: any = 0;

  voidChat: string = 'wakka';
  voidref: string = 'hettie';

  messageForm = this.fb.group({
    messageSubmitted: new FormControl(''),
  });

  constructor(
    private fb: FormBuilder,
    private firestore: AngularFirestore,
    private userService: UserService,
    public dialog: MatDialog
  ) {
    this.chatsInstanceCollection = this.firestore
      .collection('messages')
      .doc(this.notificationRef)
      .collection('chats', (ref) => ref.orderBy('date', 'asc'));
    this.chtInst = this.chatsInstanceCollection.snapshotChanges().pipe(
      map((actions: any[]) => {
        return actions.map((a) => {
          const data = a.payload.doc.data() as ChatStream;
          data.chatInstanceToUser = a.payload.doc.id;
          console.log(data);
          return { ...data };
        });
      })
    );
    this.chtInst.forEach((l) => {
      this.ll = this.ll + 1;
      console.log(this.ll);
    });
    console.log(this.chtInst, this.numero, this.hexa);
    this.numero = this.chtInst.subscribe.length;
    console.log(this.counting, this.numero);

    //const source = new Subject<ChatStream>();
    //const result = source.pipe(isEmpty());
    //source.subscribe(x => console.log(x));
    //result.subscribe(x => console.log(x));
    //console.log("is It Empty?", source, result);

    /*const source = this.chtInst.pipe(isEmpty());

    source.subscribe(x => {
      console.log(x);
      this.obsEmpty = x;
      if (this.obsEmpty === false) {
        this.noInstanceMessage = true
        
      } else {
        this.noInstanceMessage = false
      }
    });*/

    this.showLoader = false;
  }

  ngOnInit(): void {
    console.log(this.notificationRef);
  }

  changeHexa() {
    this.hexa = 'next';
  }

  loadContent() {
    this.obsEmpty2 = 'lad';
  }

  endLoader() {
    this.pointer = 'empty';
    this.obsEmpty2 = 'lad';
  }

  blank() {
    this.obsEmpty2 = 'load';
  }

  clear() {
    this.firestore
      .collection('messages')
      .doc(this.notificationRef)
      .collection('chats')
      .get()
      .toPromise()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      });
  }

  hideDefault() {
    this.noInstanceMessage = true;
  }

  showDefault() {
    this.noInstanceMessage = false;
  }

  toggleVisibilty() {
    this.visibilityInstance = !this.visibilityInstance;
    this.visibiltyContent = !this.visibiltyContent;
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  onSendMessage() {
    var message = this.messageForm.value.messageSubmitted;
    var preciseDate = new Date();
    var uuid = this.uuidv4();
    console.log(message, this.rmUser, this.rmDevice);

    var locIns: Chat = {
      fromUser: this.notificationRef,
      message: message,
      toUser: this.rmUser,
      date: preciseDate,
      id: uuid,
    };

    this.chatsContentCollection?.add(locIns);

    this.firestore.collection('rawMessages').doc(uuid).set({
      fromUser: this.notificationRef,
      message: message,
      source: 'web',
      toUser: this.rmUser,
      date: preciseDate,
      device: this.rmDevice,
    });
    console.log('rawmessage doc created', locIns);
    this.messageForm.reset();
  }

  onSelectInstance(chtInst: ChatStream) {
    this.toggleVisibilty();

    console.log('wt is the instance', this.chtInst);

    this.chatDevice = chtInst.deviceName;
    if (chtInst.isInvite) {
      this.whoDevice = 'Your Device';
    } else {
      this.whoDevice = 'Other Device';
    }

    this.chatUser = chtInst.chatInstanceToUser;
    this.chtHeader = chtInst.name;
    this.defaultMessage = true;

    var fromUserr = chtInst.chatInstanceToUser.substring(
      0,
      chtInst.chatInstanceToUser.indexOf('_')
    );
    var deviceIdd = chtInst.chatInstanceToUser.substring(
      chtInst.chatInstanceToUser.indexOf('_') + 1,
      chtInst.chatInstanceToUser.length
    );

    this.rmUser = fromUserr;
    this.rmDevice = deviceIdd;
    this.rmName = chtInst.deviceName;

    console.log(this.rmUser);

    if (chtInst.inviteAccepted === false) {
      if (chtInst.isInvite === true) {
        this.dialog.open(ChatInviteDialogComponent, {
          data: {
            fromUser: fromUserr,

            toUser: this.notificationRef,

            device: deviceIdd,
          },
        });
      }
    } else {
      this.chatsContentCollection = this.firestore
        .collection('messages')
        .doc(this.notificationRef)
        .collection('chats')
        .doc(chtInst.chatInstanceToUser)
        .collection('message', (ref) => ref.orderBy('date', 'asc'));
      this.chtCnt = this.chatsContentCollection.snapshotChanges().pipe(
        map((actions: any[]) => {
          return actions.map((a) => {
            const data = a.payload.doc.data() as Chat;
            data.id = a.payload.doc.id;
            console.log(data);
            return { ...data };
          });
        })
      );

      console.log(this.chtCnt);
    }
  }

  onSelectInvite(chtInv: Invites) {
    this.invDevice = chtInv.device;
    this.invToUser = chtInv.toUser;
    this.firestore.collection('invitesAccepted').add({
      date: new Date(),
      device: chtInv.device,
      fromUser: chtInv.fromUser,
      toUser: chtInv.toUser,
    });
  }
}
