<div>
    <div class="exitButtonRight">
        <div>
            <mat-icon class="exitButton" (click)="close()">close</mat-icon>
        </div>
    </div>

    <div>
        <p class="fontHead">{{ comsData.comsHead }}</p>
    </div>

    <div>
        <p class="fontContent">{{ comsData.comsContent }}</p>
    </div>


</div>