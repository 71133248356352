<div class="profile">
  <div class="nother">
    <div class="profilePicNew" (click)="openCam()">
      <div class="favProfile"></div>
    </div>

    <div class="nameSpace">
      <div class="nameWrap">
        <h1 *ngFor="let doc of UserArray" class="h1h">
          {{ doc.firstName }}&nbsp;{{ doc.lastName }}
        </h1>
      </div>
    </div>

    <div *ngFor="let doc of UserArray">
      <div *ngIf="doc.contact === undefined && doc.saIdNumber === undefined">
        <p>Profile 60% completed</p>
        <div class="barWrapper">
          <div class="progressBar1">
            <div class="progressBar4"></div>
          </div>
        </div>
      </div>
      <div *ngIf="doc.contact === undefined && doc.saIdNumber !== undefined">
        <p>Profile 80% completed</p>
        <div class="barWrapper">
          <div class="progressBar1">
            <div class="progressBar5"></div>
          </div>
        </div>
      </div>
      <div *ngIf="doc.contact !== undefined && doc.saIdNumber === undefined">
        <p>Profile 80% completed</p>
        <div class="barWrapper">
          <div class="progressBar1">
            <div class="progressBar5"></div>
          </div>
        </div>
      </div>
      <div *ngIf="doc.contact !== undefined && doc.saIdNumber !== undefined">
        <p>100% complete!</p>
        <div class="barWrapper">
          <div class="progressBarComplete"></div>
        </div>
      </div>
    </div>

    <div class="infobox">
      <h1 *ngFor="let doc of UserArray" class="h11">
        First Name:&nbsp;
        <h1 class="entry">{{ doc.firstName }}&nbsp;</h1>
      </h1>

      <h1 *ngFor="let doc of UserArray" class="h11">
        Last Name:&nbsp;
        <h1 class="entry">{{ doc.lastName }}&nbsp;</h1>
      </h1>
      <h1 class="h11">
        Email:&nbsp;
        <h1 class="entry">{{ emailAddress }}</h1>
      </h1>
      <h1 *ngFor="let doc of UserArray" class="h11">
        Contact # :&nbsp;
        <div *ngIf="doc.contact === undefined" class="show">
          <button
            class="greyButton2"
            mat-flat-button
            (click)="openSubmittContact()"
          >
            Contact Number</button
          >&nbsp;
        </div>
        <div *ngIf="doc.contact !== undefined" class="show">
          <h1 class="entry">{{ doc.contact }}</h1>
          &nbsp;
        </div>
      </h1>
      <h1 *ngFor="let doc of UserArray" class="h11">
        Id:&nbsp;
        <div *ngIf="doc.saIdNumber === undefined" class="show">
          <button class="greyButton2" (click)="openSubmittId()" mat-flat-button>
            ID/Passport Number</button
          >&nbsp;
        </div>
        <div *ngIf="doc.saIdNumber !== undefined" class="show">
          <h1 class="entry">{{ doc.saIdNumber }}</h1>
          &nbsp;
        </div>
      </h1>
    </div>

    <div>
      <button
        mat-flat-button
        class="generalButton"
        style="text-align: center"
        (click)="onChangePassword()"
      >
        Change Password
      </button>
    </div>
  </div>
  <!--<div class="barWrapper">

        <div class="progressBar1">
            <div class="progressBar2">
            </div>
        </div>
    </div>


    <div class="barWrapper">

        <div class="progressBar1">

            <div class="progressBar3">

            </div>

        </div>
    </div>

    <div class="barWrapper">

        <div class="progressBar1">

            <div class="progressBar4">

            </div>

        </div>
    </div>


    <div class="barWrapper">

        <div class="progressBar1">

            <div class="progressBar5">
            </div>

        </div>
    </div>


    <div class="barWrapper">

        <div class="progressBarComplete">

        </div>
    </div>-->
  <div></div>
</div>
