<div class="relive">
  <div class="blueOrb favProfile"></div>
  <div class="etest">
    <div class="mWrap">
      <div class="masterMobile">
        <div class="nameWrap">
          <h1 *ngFor="let doc of currentUserArray" class="h1h">
            {{ doc.firstName }}&nbsp;{{ doc.lastName }}
          </h1>
        </div>

        <div class="mobileBoxlet">
          <div>
            <p class="newBoxTitleMobile">Personal</p>
            <p class="newBoxDescrMobile">
              All your portable and household items
            </p>
          </div>
          <div>
            <img
              class="iconSidebarSizing"
              src="../../assets/Captunehomepersonalicon.PNG"
            />
          </div>
        </div>
        <div class="mobileBoxlet">
          <div>
            <p class="newBoxTitleMobile">Office</p>
            <p class="newBoxDescrMobile">
              All your items allocated to you by your employer or business.
            </p>
          </div>
          <div>
            <img
              class="iconSidebarSizing"
              src="../../assets/newhomeofficeicen.PNG"
            />
          </div>
        </div>
        <div class="mobileBoxlet">
          <div>
            <p class="newBoxTitleMobile">For Sale</p>
            <p class="newBoxDescrMobile">
              All you items that you are actively selling on any online or
              offline marketplace.
            </p>
          </div>
          <div>
            <img
              class="iconSidebarSizing"
              src="../../assets/newhomeforlsale.PNG"
            />
          </div>
        </div>

        <div class="mobileBoxlet">
          <div>
            <p class="newBoxTitleMobile">Lost / Stolen</p>
            <p class="newBoxDescrMobile">
              Only mark items as “Stolen” if you have reported the incident to
              local law enforcement.
            </p>
          </div>
          <div>
            <img
              class="iconSidebarSizing"
              src="../../assets/newlostorstolenhomeicon.PNG"
            />
          </div>
        </div>

        <div class="mobileButMargin" style="text-align: center">
          <div class="ghost"></div>
          <button
            class="generalButton"
            (click)="openRegisterNewDevice()"
            mat-flat-button
          >
            Register a Device
          </button>
        </div>
      </div>
    </div>

    <div class="master">
      <div class="bringIn">
        <div class="nameSpace">
          <div class="nameWrap">
            <h1 *ngFor="let doc of currentUserArray" class="h1h">
              {{ doc.firstName }}&nbsp;{{ doc.lastName }}
            </h1>
          </div>
        </div>
        <div class="newboxtainer">
          <div class="boxtainerliner">
            <div class="linertop">
              <div class="boxlets">
                <div>
                  <p class="newBoxTitle">Personal</p>
                  <p class="newBoxDescr">
                    All your portable and household items
                  </p>
                </div>
                <div>
                  <img
                    class="iconSidebarSizing"
                    src="../../assets/Captunehomepersonalicon.PNG"
                  />
                </div>
              </div>
              <div class="boxlets">
                <div>
                  <p class="newBoxTitle">Office</p>
                  <p class="newBoxDescr">
                    All your items allocated to you by your employer or
                    business.
                  </p>
                </div>
                <div>
                  <img
                    class="iconSidebarSizing"
                    src="../../assets/newhomeofficeicen.PNG"
                  />
                </div>
              </div>
            </div>
            <div class="linertop">
              <div class="boxlets">
                <div>
                  <p class="newBoxTitle">For Sale</p>
                  <p class="newBoxDescr">
                    All you items that you are actively selling on any online or
                    offline marketplace.
                  </p>
                </div>
                <div>
                  <img
                    class="iconSidebarSizing"
                    src="../../assets/newhomeforlsale.PNG"
                  />
                </div>
              </div>
              <div class="boxlets">
                <div>
                  <p class="newBoxTitle">Lost / Stolen</p>
                  <p class="newBoxDescr">
                    Only mark items as “Stolen” if you have reported the
                    incident to local law enforcement.
                  </p>
                </div>
                <div>
                  <img
                    class="iconSidebarSizing"
                    src="../../assets/newlostorstolenhomeicon.PNG"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ghost"></div>

        <div style="text-align: center">
          <button
            class="generalButton"
            (click)="openRegisterNewDevice()"
            mat-flat-button
          >
            Register a Device
          </button>
        </div>
      </div>
      <!--<div class="newPicWrap">
        <div class="profilePicNew">
          <div class="favProfile"></div>
        </div>
      </div>-->
    </div>
  </div>
</div>






