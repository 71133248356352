<script
  type="text/javascript"
  src="http://maps.googleapis.com/maps/api/js?libraries=places‌​&sensor=false"
></script>
<p>Tell us where your device was stolen</p>

<div class="container">
  <div class="form-group">
    <input
      type="text"
      class="form-control"
      (keydown.enter)="$event.preventDefault()"
      placeholder="Search Nearest Location"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="off"
      type="text"
      #search
    />
  </div>

  <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" #AgmMap>
    <agm-marker
      [latitude]="latitude"
      [longitude]="longitude"
      [markerDraggable]="true"
      (dragEnd)="markerDragEnd($event)"
    ></agm-marker>
  </agm-map>

  <h5>Address: {{ address }}</h5>

  <button
    mat-flat-button
    class="generalButton"
    (click)="confirmLocation()"
    style="text-align: center"
  >
    Confirm Stolen Location
  </button>
</div>
