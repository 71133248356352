import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';


interface device {
  name: string;
}




interface deviceRegisterValues {
  brand: string;
  nickname: string;
  imei: string;
}


@Component({
  selector: 'app-register-device-dialog',
  templateUrl: './register-device-dialog.component.html',
  styleUrls: ['./register-device-dialog.component.css']
})
export class RegisterDeviceDialogComponent implements OnInit {


  deviceRegisterForm = this.fb.group({
    brandSubmitted: new FormControl('', [Validators.required]),
    nicknameSubmitted: new FormControl('', [Validators.required]),
    imeiSubmitted: new FormControl('', [Validators.required, Validators.pattern('[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]')]),
  })
  brandControl = new FormControl('', Validators.required)
  selectFormControl = new FormControl('', Validators.required)

  public hasError = (controlName: string, errorName: string) => {
    return this.deviceRegisterForm.controls[controlName].hasError(errorName);
  }

  spinner: NgxSpinnerService;
  autoiemi: any;
  autoRegisterIemi!: string;
  carryImei: any;

  public brandz: any;
  public nicknamez: any;
  public imeiz: any;

  constructor(
 

    public authService: AuthenticationService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<RegisterDeviceDialogComponent>,
    private constSpinner: NgxSpinnerService,
  ) {
    this.spinner = constSpinner;
  }

  selected = 'option2';

  

  devices: device[] = [
    { name: 'Samsung' },
    { name: 'Apple' },
    { name: 'Huawei' },
    { name: 'LG' },
    { name: 'BlackBerry' },
    { name: 'Xiaomi' },
    { name: 'Mobicel' },
    { name: 'Nokia' },
  ];

  onRegisterDevice() {
    this.spinner.show();
    console.log(this.brandControl.value.name);
    var brand: string = this.deviceRegisterForm.value.brandSubmitted
    var nickname: string = this.deviceRegisterForm.value.nicknameSubmitted
    var imei: string = this.deviceRegisterForm.value.imeiSubmitted
    console.log("values submitted to register device", brand , nickname, imei)
    this.authService.registerDevice(brand , nickname, imei);
    this.spinner.hide();
    this.dialogRef.close();
  }

  //animalControl.value?.sound

  ngOnInit(): void {
  }

  exit() {
    this.dialog.closeAll();
  }


}
