import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Chat2Component } from './chat2/chat2.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DeviceComponent } from './device/device.component';
import { HomedashComponent } from './homedash/homedash.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LoginGuardGuard } from './login-guard.guard';
import { ProfileComponent } from './profile/profile.component';
import { SignInPageComponent } from './sign-in-page/sign-in-page.component';
import { GmapsComponent } from './stolenidevice/gmaps/gmaps.component';
import { PrivacyPolicyComponent } from './terms-of-service/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';


const routes: Routes = [
  { path: '', component: LandingPageComponent },
  { path: 'signIn', component: SignInPageComponent },
  {
    path: 'dashboard', component: DashboardComponent,
    canActivate: [LoginGuardGuard], 
    children: [
      { path: '', component: HomedashComponent, outlet: "sidenav"},
      { path: 'home', component: HomedashComponent, outlet: "sidenav"},
      { path: 'devices', component: DeviceComponent, outlet: "sidenav"},
      { path: 'chat', component: Chat2Component, outlet: "sidenav"},
      { path: 'profile', component: ProfileComponent, outlet: "sidenav"},
      { path: 'terms', component: TermsOfServiceComponent, outlet: "sidenav"},
      { path: 'privacy', component: PrivacyPolicyComponent, outlet: "sidenav"},
      { path: 'chat2', component: Chat2Component, outlet: "sidenav"},
    ]
  },
  { path: 'termsService', component: TermsOfServiceComponent },
  { path: 'PrivacyPolicy', component: PrivacyPolicyComponent },
  
  //{ path: '',   redirectTo: '/signIn', pathMatch: 'full' }, // redirect to `first-component`*/
  { path: '**', component:  SignInPageComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [ LoginGuardGuard ]
})
export class AppRoutingModule {

}
