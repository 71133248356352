import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceInfoDialogComponent } from '../device-info-dialog/device-info-dialog.component';
import { RegisterDeviceDialogComponent } from '../dialogs/register-device-dialog/register-device-dialog.component';
import { UpdateDeviceStateDialogComponent } from '../dialogs/update-device-state-dialog/update-device-state-dialog.component';
import { Device } from '../interfaces/Device';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user.service';

import { switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.css']
})
export class DeviceComponent implements OnInit {

  public devicesCollection: AngularFirestoreCollection<Device> | undefined;
  public devices: Observable<Device[]> | undefined;
  public carryImei: any;
  public autoiemi!: any;
  autoRegisterIemi!: string;
  iemieToUse: any;


  constructor(
    private firestore: AngularFirestore,
    private authService: AuthenticationService,
    public dialog: MatDialog,
    private afAuth: AngularFireAuth,
    private userService: UserService,
    private route: ActivatedRoute
  ) {
    this.devicesCollection = this.firestore.collection('products', ref => ref.where('userId', '==', userService.getUserLoggedIn()).where('verified', '==', true));
    this.devices = this.devicesCollection.snapshotChanges().pipe(
      map((actions: any[]) => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Device;
          data.id = a.payload.doc.id;
          console.log(data);
          return { ...data };
        });
      }));


    console.log(this.devices);


    console.log(this.devices);
  }

  ngOnInit(): void {
    this.iemieToUse = this.route.paramMap.pipe(
      switchMap(params => {
        this.autoiemi = Number(params.get('autoiemi'));
        return this.autoiemi;
        
      })
    );

    console.log(this.autoiemi, "email imie", this.autoRegisterIemi,);
  }

  imeiURL(email: string, password: string, autoiemi: any) {
    this.authService.continueFlag = true;
  }

  openRegisterNewDevice() {
    this.dialog.open(RegisterDeviceDialogComponent);
  }

  openChangeDeviceState() {
    this.dialog.open(UpdateDeviceStateDialogComponent);
  }



  viewDevice(device: Device) {

    console.log(device);
    this.dialog.open(DeviceInfoDialogComponent, {
      data: {
        nickname: device.nickname,
        brand: device.brand,
        name: device.name,
        model: device.model,
        state: device.state,
        id: device.id,
        imei: device.imei,
        verified: device.verified,
      },
    })
  }

  openAutoReg() {

    console.log(this.autoiemi);
    this.dialog.open(RegisterDeviceDialogComponent,  {
      data: {

        autoRegisterIemi: this.autoiemi,

      },
    })
  }





}
