import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EmailNotVerifiedComponent } from './email-not-verified/email-not-verified.component';
import { AuthenticationService } from './services/authentication.service';
import { UserService } from './services/user.service';
import { UrlparService } from './services/urlpar.service';




@Injectable({
  providedIn: 'root'
})
export class LoginGuardGuard implements CanActivate {

  tempIeme: any;
  urlIm: any;
  autoimei: any;



  constructor(
    private authService: AuthenticationService, 
    private router: Router, 
    private route: ActivatedRoute,





  ) { }


  




  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): true|UrlTree {

   




    console.log('AuthGuard#canActivate called', this.tempIeme, this.urlIm);

    const url: string = state.url;
    this.route.queryParams.subscribe(params => {
      this.autoimei = params['autoiemi'];
      console.log(this.autoimei)
    });

    return this.checkLogin(url);


    //return this.isAuthenticated();
    

  }

  checkLogin(url: string): true|UrlTree {
    if (this.authService.isLoggedIn) { return true; }

    // Store the attempted URL for redirecting
    url;
    this.authService.redirectUrl = url;

    // Create a dummy session id
    const sessionId = 123456789;

    const navigationExtras: NavigationExtras = {
      queryParams: { session_id: sessionId },
      fragment: 'autoieme'
    };
    console.log(navigationExtras)



    // Redirect to the login page with extras
    return this.router.createUrlTree(['/signIn'], navigationExtras);

    
    
  }

}


/*
canActivate() {
    if (this.authenticatedTrue = true) {

      console.log("OnlyLoggedInUsers");
    this.router.navigate(['/dashboard']);

    } else {

      console.log("go away");
      this.router.navigate(['**']);


    }




  }  */





