import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from '../interfaces/User';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private firestore: AngularFirestore) {}
  // Set data on localStorage
  setUserLoggedIn(userId: string, email: string) {
    localStorage.setItem('userId', userId);
    console.log('saved on localStorage');
    localStorage.setItem('email', email);
  }
  // get data on localStorage
  getUserLoggedIn() {
      return localStorage.getItem('userId');
  }

  getUser() {

  }
  // Optional: clear localStorage
  clearLocalStorage() {
    localStorage.clear();
  }
}