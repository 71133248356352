import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from "src/app/services/authentication.service";

import { Observable } from 'rxjs';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { QuickcheckResultComponent } from '../quickcheck-result/quickcheck-result.component';
import { DashqcheckresultComponent } from 'src/app/dashqcheckresult/dashqcheckresult.component';
import { QcheckIfForSaleResultComponent } from 'src/app/dashqcheckresult/qcheck-if-for-sale-result/qcheck-if-for-sale-result.component';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-quickcheck-dialog',
  templateUrl: './quickcheck-dialog.component.html',
  styleUrls: ['./quickcheck-dialog.component.css']
})
export class QuickcheckDialogComponent implements OnInit {


  @ViewChild('txtInput')
  txtInput!: ElementRef;

  quickCheckForm = this.fb.group({
    imeiSubmitted: new FormControl('', [Validators.pattern('[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]')]),
  })


  emailDeviceReportForm = this.fb.group({
    reportEmail: new FormControl(''),
  });


  public quickcheckResponse: any;



  constructor(
    private firestore: AngularFirestore,

    public authService: AuthenticationService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    private fns: AngularFireFunctions,
    private spinner: NgxSpinnerService,
  ) {
  }

  ngOnInit() {

  }


  public hasError = (controlName: string, errorName: string) => {
    return this.quickCheckForm.controls[controlName].hasError(errorName);
  }


  private quickcheckFunction = this.fns.httpsCallable('quickcheck');

  quickcheck() {
    
    this.spinner.show();
    console.log("Doing quickcheck")
    
    console.log(this.getAllDocs())
    this.quickcheckFunction({ input: this.quickCheckForm.value.imeiSubmitted, source: 'Web', user: "" }).subscribe(result => {
      console.log(result);
      this.spinner.hide();
      if (result.deviceState == "For Sale") {

        this.dialog.open(QcheckIfForSaleResultComponent, {
          data: {
            name: result.name,
            claimed: result.claimed,
            deviceState: result.deviceState,
            deviceUser: result.deviceUser,
            searchId: result.searchId,
            status: result.status,
            blacklistStatus: result.blacklistStatus,
            device: result.deviceKey,
          },
        });

      } else {

        this.dialog.open(DashqcheckresultComponent, {
          data: {
            name: result.name,
            claimed: result.claimed,
            deviceState: result.deviceState,
            deviceUser: result.deviceUser,
            searchId: result.searchId,
            status: result.status,
            blacklistStatus: result.blacklistStatus,
          },
        });


      }

      // return this.quickcheckFunction({ input: this.quickCheckForm.value.imeiSubmitted, source: 'Web', user: null });
    })

  }

  closeDialog() {
    this.dialog.closeAll()
  }


  getAllDocs() {
    console.log("get all docs",this.quickCheckForm.value.imeiSubmitted)
    const ref = this.firestore.collection('products', ref => ref.where("imei", "==", this.quickCheckForm.value.imeiSubmitted));
    

  }




  onSubmitEmailForReport() { }

}
