<div class="wider" style="text-align:center;">
    <div class="exitButtonRight">
        <div>
            <img class="logo" src="/assets/OwnedbyBlackChatBack.png">
        </div>
        <div>
            <mat-icon class="exitButton" (click)="close()">close</mat-icon>
        </div>
    </div>
    
    <div class="ghost"></div>
    
    <h2 class="fontOrange">Device Information</h2>

    <div class="infoBlock">
        <div>
            <div class="inRow">
                <p class="boldDescription">Nickname:&nbsp;&nbsp;</p>
                <p class="filledValues">{{ deviceInfoData.nickname }}</p>
            </div>
            <div class="inRow">
                <p class="boldDescription">Brand:&nbsp;&nbsp;</p>
                <p class="filledValues">{{ deviceInfoData.brand }}</p>
            </div>
            <div class="inRow">
                <p class="boldDescription">Name:&nbsp;&nbsp;</p>
                <p class="filledValues">{{ deviceInfoData.name }}</p>
            </div>
            <div class="inRow">
                <p class="boldDescription">Model:&nbsp;&nbsp;</p>
                <p class="filledValues">{{ deviceInfoData.model }}</p>
            </div>
            <div class="inRow">
                <p class="boldDescription">IMEI:&nbsp;&nbsp;</p>
                <p class="filledValues">{{ deviceInfoData.imei }}</p>
            </div>
            <div class="inRow">
                <p class="boldDescription">Device status:&nbsp;&nbsp;</p>
                <p class="filledValues">{{ deviceInfoData.state }}</p>
            </div>
            <div class="inRow">
                <p class="boldDescription">Verified:&nbsp;&nbsp;</p>
                <p class="filledValues">{{ deviceInfoData.verified }}</p>
            </div>
        </div>
    </div>
    <div class="ghost"></div>
    <div class="ghost"></div>
</div>

<div *ngIf="deviceInfoData.state !== 'Stolen'  ">
    <form [formGroup]="deviceStateForm" class="inputs" style="text-align:center;" (ngSubmit)="onStateChange()">
        <mat-form-field appearance="legacy" style="text-align:center; display:block;">
            <mat-label>Select a Status to apply</mat-label>
            <mat-select appearance="legacy" formControlName="stateSubmitted">
                <mat-option *ngFor="let state of states" [value]="state.description">{{state.description}}</mat-option>
            </mat-select>
            <!--<mat-error *ngIf="hasError('brandSubmitted', 'required')">Brand is required</mat-error>-->
        </mat-form-field>
        <button mat-flat-button class="generalButton" type="submit" style="text-align:center;">Change Status</button>
    </form>
</div>

<div *ngIf="deviceInfoData.state === 'Stolen'  ">
    <form  class="inputs" style="text-align:center;" >
        <mat-form-field appearance="legacy" style="text-align:center; display:block;">
            <mat-label>Please Supply Case Number</mat-label>
            <input matInput appearance="legacy" formControlName="stateSubmitted">
                
        
            <!--<mat-error *ngIf="hasError('brandSubmitted', 'required')">Brand is required</mat-error>-->
        </mat-form-field>
        <button mat-flat-button  style="text-align:center;">Confirm case number</button>
    </form>
</div>

