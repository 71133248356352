import { Component, Inject, OnInit, ViewChild, ElementRef, NgZone, AfterViewInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import 'firebase/firestore'
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Device } from '../interfaces/Device';
import { DeviceStateService } from '../services/device-state.service';
import { MapsAPILoader } from '@agm/core';




@Component({
  selector: 'app-stolenidevice',
  templateUrl: './stolenidevice.component.html',
  styleUrls: ['./stolenidevice.component.css']
})
export class StolenideviceComponent implements AfterViewInit  {

  public deviceInfoData: Device;
  latitude!: number;
  longitude!: number;
  markerLatitude!: number;
  markerLongitude!: number;
  zoom!: number;
  address!: string;
  private geoCoder: google.maps.Geocoder;

  @ViewChild('search')
  public searchElementRef!: ElementRef;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: Device,
    private dialogRef: MatDialogRef<StolenideviceComponent>,
    private dialog: MatDialog,
    public stateService: DeviceStateService,
    private firestore: AngularFirestore,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
  ) {
    this.deviceInfoData = data;
    this.geoCoder = new google.maps.Geocoder;

   }
  ngAfterViewInit(): void {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
  
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
  
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
  
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }

  geopoint(lat: number, lng: number) {
    return  new firebase.firestore.GeoPoint(lat, lng)
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.markerLatitude = position.coords.latitude;
        this.markerLongitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude: number, longitude: number) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
  
    });
  }

  markerDragEnd($event: any) {
    console.log('Event : '+JSON.stringify($event));
     this.markerLatitude = $event.coords.lat;
     this.markerLongitude = $event.coords.lng;
     this.getAddress($event.coords.lat, $event.coords.lng);
  }

  close() {
    this.dialog.closeAll();
  }

  confirmLocation() {
    console.log(this.deviceInfoData.state);
    console.log(this.deviceInfoData.id);
    console.log(this.markerLatitude);
    console.log(this.markerLongitude);

    var newState: string = this.deviceInfoData.state;
    var documentId: string = this.deviceInfoData.id;
    console.log("gonna change state", newState, documentId );
    this.stateService.updateState(newState, documentId)

    this.firestore.collection("cases").add({
      caseNumber: "null",
      dateReported: new Date(),
      deviceKey: this.deviceInfoData.id,
      location: this.geopoint(this.markerLatitude, this.markerLongitude),
      address: this.address,
    })
    
  }


  logLocationChange(event: any) {
    console.log(event);
  }

}
